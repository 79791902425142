import React, { useState, useEffect } from 'react';
import { Grid, Row, Col, FormGroup, ControlLabel, FormControl } from 'react-bootstrap'

export const SuggestionComponent = ({
    value,
    fetchSuggestions,
    renderValue,
    renderSuggestion,
    noResultsMessage,
    loadingMessage,
    onDefaultValue,
    onChangeValue,
    onKeyDown,
    placeholder,
    disabled,
    helpText,
    helpTextType,
    onClean,
    delay = 500,
    inputRef = null,
    minLength = 3 }) => {
    const [suggestions, setSuggestions] = useState([]);
    const [debounce, setDeounce] = useState(null);
    const [loading, setLoading] = useState(false);
    const [isFocus, setFocus] = useState(false);
    const [currentSearch, setCurrentSearch] = useState('');
    const [inputValue, setInputValue] = useState(value);

    useEffect(() => {
        if (value == null || value == undefined) {
            clean()
        }
    }, [value])

    function sendChangeIfExist(value) {
        if (onChangeValue != null && onChangeValue != undefined && value != null)
            onChangeValue(value)
    }

    function clean() {
        setInputValue(null)
        setCurrentSearch('')
        setSuggestions([])
        if (onClean !== null && onClean !== undefined)
            onClean()
    }

    const handleChange = async ({ target }) => {
        setDeounce(clearTimeout(debounce))

        const value = target.value;

        if (value === null || value === undefined || value === '') {
            clean()
            return
        }

        setInputValue(onDefaultValue(value));
        sendChangeIfExist(onDefaultValue(value))
        setCurrentSearch(value);

        if (value.length < minLength) return
        const timerId = setTimeout(async () => {

            if (value) {
                setLoading(true);
                try {
                    const results = await fetchSuggestions(value);

                    const sanitizedResult = results === null || results === undefined
                        ? []
                        : results;

                    setSuggestions(sanitizedResult);
                } catch (error) {
                    setSuggestions([]);
                } finally {
                    setLoading(false);
                }
            } else {
                setSuggestions([]);
            }

        }, delay);
        setDeounce(timerId)
    };

    const handleBlur = () => {
        setTimeout(() => {
            setSuggestions([]);
            setFocus(false);
        }, 300)
    };
    const handleFocus = () => {
        setFocus(true);
        if (currentSearch.length > 0) {
            handleChange({ target: { value: currentSearch } })
        }
    };


    return (
        <div style={{ position: 'relative' }}>
            <input
                className='form-control'
                ref={inputRef}
                type="text"
                value={currentSearch}
                onChange={handleChange}
                onFocus={handleFocus}
                onKeyDown={onKeyDown}
                onBlur={handleBlur}
                placeholder={placeholder}
                disabled={disabled}
                style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid #ccc' }}
            />
            {helpText != undefined && helpText != "" && <div style={{ fontSize: '13px', marginTop: '.5rem' }} className={`text-${helpTextType}`}>{helpText}</div>}
            {inputValue && <a onClick={clean} style={{ position: 'absolute', right: '10px', top: '10px', cursor: 'pointer', color: '#ababab' }}>X</a>}
            {loading && <div style={{ position: 'absolute', width: '100%', backgroundColor: '#fff', padding: '1.25rem .75rem', textAlign: "center", height: '43px', border: '1px solid #ccc', zIndex: 10 }}>{loadingMessage}</div>}
            {suggestions?.length === 0 && !loading && !inputValue && isFocus && !currentSearch && currentSearch.length > 0 && (
                <div style={{ position: 'absolute', width: '100%', backgroundColor: '#fff' }}>{noResultsMessage}</div>
            )}
            {suggestions?.length > 0 && !loading && (
                <div style={{ margin: 0, padding: '1.25rem .75rem', position: 'absolute', width: '100%', backgroundColor: 'white', zIndex: 10, border: '1px solid #ccc', overflowY: 'auto', maxHeight: '200px' }}>
                    <ul style={{ listStyleType: 'none', margin: 0, padding: 0, }}>
                        {suggestions.map((suggestion, index) => (
                            <li
                                key={index}
                                onClick={(e) => {
                                    setInputValue(suggestion);
                                    setSuggestions([]);
                                    setCurrentSearch(renderValue(suggestion))
                                    sendChangeIfExist(suggestion)
                                }}
                                style={{ padding: '10px', cursor: 'pointer' }}
                                onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#f0f0f0')}
                                onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = 'white')}
                            >
                                {renderSuggestion(suggestion)}
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};
