import React from 'react';
import '../../../assets/css/common.css';
import { InventoryMoveForm } from '../Shared/InventoryMoveForm';
import { MoveTypes } from '../../../api/Inventory/Entities/InventoryMovementEntities';
import { useTranslation } from 'react-i18next';
import { Assigments } from '../../../api/Inventory/Entities/InventoryEntities';

export function AddStockFormPage({ match: { params } }) {
    const { t } = useTranslation();

    return (<>
        <InventoryMoveForm
            title={t('inventory.movements.commons.stockEntry')}
            moveId={params.moveId ?? 0}
            moveType={MoveTypes.AddStock.Name}
            assignmentType={Assigments.General} />
    </>)
}



