import React from "react";
import { useState } from 'react';
import { ConfirmationModal } from './ConfirmationModal';

const state = {
    show: false,
    value: "",
    onClose: () => { },
    onConfirmation: () => { },
    configuration: {
        title: "",
        textContent: "",
        buttonText: ""
    }
}

export default function useConfirmationModal() {
    const [isShowing, setIsShowing] = useState();
    const [modalState, setModalState] = useState(state);

    const _close = () => setModalState({ ...state, configuration: { title: "", textContent: "", buttonText: "" }, show: false });

    const actions = {
        Show: ({ title = "", textContent = "", buttonText = "", buttonType = "success", onConfirmation = () => { } }) => {
            setModalState({ ...state, show: true, configuration: { title, textContent, buttonText, buttonType }, onConfirmation: async () => {
                await onConfirmation()
                _close()
            } })
        },
        Close: () => _close()
    }
    const modal = () => (<ConfirmationModal state={{ ...modalState, onClose: () => actions.Close() }} > </ConfirmationModal>)

    return [modal, actions]
};