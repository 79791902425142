import React, { useEffect, useState } from 'react';
import Card from '../../../components/Cards/Card';
import BootstrapTable from 'react-bootstrap-table-next';
import { Col, Row, Grid, Modal, Button } from 'react-bootstrap';
import paginationFactory from 'react-bootstrap-table2-paginator';
import Loader from '../../../components/Common/Loader';
import { useTranslation } from 'react-i18next';
import useReleases from '../Hooks/useReleases';
import { PaginationRequest } from '../../../api/Common/Util';
import moment from 'moment';
import useItem from '../Hooks/useItem';
import { Field } from '../../../components/Common/Field/Field';
import InventoryStockService from '../../../api/Inventory/InventoryStockService';
import { useModalHook } from '../../../components/Modals/useModalContext';
import ReleaseStockModal from '../Shared/ReleaseStockModal';
import { Tooltip } from '../../../components/Utils/Tooltips';
import { More } from '../../../components/Icons/Icons';
import { EnumValue } from '../../../api/Freezer/Entities/Utils';
import SearchHeader from '../../../components/Common/SearchHeader';

export default function InventoryItemDetailPage({ match: { params } }) {
    const [openStockModal, StockModal] = useModalHook(ReleaseStockModal)
    const { t } = useTranslation();
    const { itemId } = params
    const [item, isItemLoading] = useItem(itemId);
    const [stock, setStock] = useState(0);
    const [pagedReleases, isReleaseLoading, navigation] = useReleases({
        preload: true,
        requestProps: {
            ... new PaginationRequest({
                pageNumber: 1,
                pageSize: 10,
                sortColumn: 'lotNumber',
            }),
            onlyAvailable: true,
            includeStock: true,
            itemId: itemId
        }
    })

    useEffect(() => {
        async function LoadStock(itemId) {
            const result = await InventoryStockService.Find({
                itemIds: [itemId]
            })
            if (result.Succeeded)
                setStock(result.Value[0]?.stock ?? 0)
        }


        if (item.id > 0)
            LoadStock(itemId);
    }, [isItemLoading])

    const columns = [
        { dataField: 'lotNumber', text: 'Lot Number', headerStyle: { width: '7rem' } },
        {
            text: 'Expiration date', headerStyle: { width: '10rem' }
            , formatter: function (_cell, release, rowIndex) {
                return moment(release.expirationDate).format("DD/MM/YYYY");
            }
        },
        {
            text: 'Stock'
            , headerStyle: { width: '5rem' }
            , formatter: function (_cell, release, rowIndex) {
                return release.stockQuantity
            }
        },
        {
            dataField: 'view',
            text: "",
            isDummyField: true,
            headerClasses: 'header-actions',
            classes: 'row-actions',
            formatter: function (_cell, row, rowIndex) {
                return (
                    <div key={rowIndex}>
                        <button className="btn-table-actions" onClick={() => { openStockModal(row.id) }}>
                            <Tooltip tooltip={'Ver detalles'} id={"tooltipedit-" + row.Id}>
                                <span aria-hidden="true"><More /></span>
                            </Tooltip>
                        </button>
                    </div>
                )
            }
        }
    ]

    function handleTableChange(type, { page, sizePerPage, sortField, sortOrder }) {
        navigation.ToPage({
            page
        })
    }

    const All = [
        new EnumValue(1, 'onlyAvailable'),
        new EnumValue(0, 'all'),
    ]
    const inputs = [
        { type: 'select', title: t('commons.type'), name: 'onlyAvailable', options: All, optionName: (op) => t(`inventory.stock.release.${op.Name}`), onChange: (e, v) => { }, disabled: false, value: null, col: { sm: 12, md: 3, lg: 3 } },
    ]
    const handleSearch = async (filter) => {
        const { onlyAvailable } = filter

        await navigation.Search({
            ... new PaginationRequest({
                pageNumber: 1,
                pageSize: 10,
                sortColumn: 'lotNumber',
            }),
            onlyAvailable: onlyAvailable.Name == 'all' ? false : true,
            includeStock: true,
            itemId: itemId
        })
    }

    return (
        <>
            <Card
                header={<div>{t('inventory.commons.medicationDetail')}</div>}
                content={<div>
                    <Loader isLoading={isItemLoading}>
                        <Row>
                            <Col md={4} sm={12}>
                                <div className="info" style={{ justifyContent: 'space-between!important' }}>
                                    <Field title={'ID'} value={item.id} spaceType={'between'} />
                                    <Field title={'Presentation'} spaceType={'between'} value={item.presentation} />
                                </div>
                            </Col>
                            <Col md={4} sm={12}>
                                <div className="info" style={{ justifyContent: 'space-between!important' }}>
                                    <Field title={'External ID'} value={item.externalId} spaceType={'between'} />
                                    <Field title={'Type'} spaceType={'between'} value={item.type} />
                                </div>
                            </Col>
                            <Col md={4} sm={12}>
                                <div className="info" style={{ justifyContent: 'space-between!important' }}>
                                    <Field title={'Stock Total'} value={stock} spaceType={'between'} />

                                </div>
                            </Col>
                        </Row>
                        <hr></hr>
                        <div>
                            <SearchHeader inputs={inputs} handleSearch={handleSearch} init={{ onlyAvailable: new EnumValue(1, 'onlyAvailable') }} buttonTitle={t('buttons.apply')} />
                        </div>
                        <Loader isLoading={isReleaseLoading}>
                            <BootstrapTable
                                bordered={false}
                                hover
                                remote
                                condensed
                                keyField='id'
                                classes="table-spaced table-spaced-small"
                                wrapperClasses="table-responsive"

                                pagination={paginationFactory({
                                    page: pagedReleases.PageNumber,
                                    sizePerPage: pagedReleases.PageSize,
                                    totalSize: pagedReleases.TotalCount,
                                    hideSizePerPage: true
                                })}

                                onTableChange={handleTableChange}

                                data={pagedReleases.Values}
                                columns={columns}
                                loading={isReleaseLoading}
                                noDataIndication={() => <>{t("commons.noData")}</>}
                            />
                        </Loader>
                    </Loader>
                </div>}
            />
            <StockModal />
        </>
    )
}