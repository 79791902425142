import { useState } from "react";
import useLoading from "../../../components/Common/useLoading";
import InventoryItemsService from "../../../api/Inventory/InventoryItemsService";
import { InventoryItem } from "../../../api/Inventory/Entities/InventoryEntities";

export default function useItem(itemId) {
    const [item, setItem] = useState(new InventoryItem({
        id: 0,
        externalId: 0,
        presentation: ""
    }));
    const [isLoading, setIsLoading] = useLoading({
        onLoad: async () => {
            const result = await InventoryItemsService.GetById(itemId);
            console.log(result);
            setItem(result.Value);
        },
        onError: (error) => {
            console.log(error)

            setItem(new InventoryItem({
                id: 0,
                externalId: 0,
                presentation: ""
            }))
        }
    });

    return [item, isLoading];
}