import React from 'react';
import Card from '../../../components/Cards/Card';
import { useTranslation } from 'react-i18next';
import BootstrapTable from 'react-bootstrap-table-next';
import { Col, Row } from 'react-bootstrap';
import moment from 'moment';
import { Add, Check, Edit, More, Remove } from '../../../components/Icons/Icons';
import { Tooltip } from '../../../components/Utils/Tooltips';
import { useHistory } from "react-router-dom"
import paginationFactory from 'react-bootstrap-table2-paginator';
import useMoves from '../Hooks/useMoves';
import { PaginationRequest, UniqueId } from '../../../api/Common/Util';
import Loader from '../../../components/Common/Loader';
import { MoveStatus, MoveTypes } from '../../../api/Inventory/Entities/InventoryMovementEntities';
import { EnumValue } from '../../../api/Freezer/Entities/Utils';
import useConfirmationModal from '../../../components/Modals/ConfirmationModal/useConfirmationModal';
import InventoryOperationService from '../../../api/Inventory/InventoryOperationService';
import { ErrorMessage, Success } from '../../../components/Notifications/Notifications';
import { useSameUser } from '../Hooks/useSameUser';
import SearchHeader from '../../../components/Common/SearchHeader';
import { WithAuth } from '../../../components/Authorization/WithAuth';

export default function InventoryOperationsPage() {
    const { t } = useTranslation();
    const [isSameUser] = useSameUser();
    const [pagedMoves, isLoading, navigation] = useMoves(new PaginationRequest({
        pageSize: 10,
        pageNumber: 1,
        sortColumn: 'createat',
        sortOrder: 'desc',
        includedTypes: 'add_stock,adjust_stock',
    }))
    const history = useHistory();
    const [Modal, modalActions] = useConfirmationModal();

    function handleAddStock() {
        history.push('/admin/inventory/addstock/0')
    }
    function handleProtocolAddStock() {
        history.push('/admin/inventory/protocol/addstock/0')
    }
    function handleAdjustStock() {
        history.push('/admin/inventory/adjuststock/0')
    }
    function handleProtocolAdjustStock() {
        history.push('/admin/inventory/protocol/adjuststock/0')
    }
    function handleShowMoveDetail(moveId) {
        history.push('/admin/inventory/moves/' + moveId)
    }

    function handleTableChange(type, { page, sizePerPage, sortField, sortOrder }) {
        navigation.ToPage({
            page
        })
    }

    function handleCancelMovement(move) {
        modalActions.Show({
            title: t("commons.deleteTitle"),
            textContent: t('commons.confirmRemove'),
            buttonText: t('buttons.delete'),
            buttonType: 'danger',
            onConfirmation: async () => {
                const result = await InventoryOperationService.CancelPending(move.id)
                if (result.Succeeded)
                    Success(t('commons.deleteSuccess'))
                else
                    ErrorMessage(t('commons.deleteError'))
            }
        })
    }

    function handleResumeMove(move) {

        switch (move.assignmentType) {
            case "protocol":
                if (MoveTypes.IsEquivalent(move.moveType, MoveTypes.AddStock))
                    history.push(`/admin/inventory/protocol/addstock/${move.id}`)
                if (MoveTypes.IsEquivalent(move.moveType, MoveTypes.AdjustStock))
                    history.push(`/admin/inventory/protocol/adjuststock/${move.id}`)
                break;
            case "general":
                if (MoveTypes.IsEquivalent(move.moveType, MoveTypes.AddStock))
                    history.push(`/admin/inventory/addstock/${move.id}`)
                if (MoveTypes.IsEquivalent(move.moveType, MoveTypes.AdjustStock))
                    history.push(`/admin/inventory/adjuststock/${move.id}`)
                break;
            default:
                throw new Error("Invalid Assignment Type")
        }
    }

    const columns =
        [
            {
                dataField: 'user.fullName', text: 'Nombre', headerStyle: { width: '15rem' }
            },
            {
                dataField: 'moveType', text: t('commons.type'), headerStyle: { width: '7rem' }
                , formatter: (cell, row) => t(`inventory.movements.moveType.${cell}`)
            },
            {
                text: t('commons.date'), headerStyle: { width: '6rem' }
                , formatter: function (_cell, move, rowIndex) {
                    return moment(move.createAt).format("DD/MM/YYYY HH:mm");
                }
            },
            {
                dataField: 'status', text: t('commons.state'), headerStyle: { width: '7rem' }
                , formatter: (cell, row) => t(`inventory.movements.moveState.${cell}`)
            },
            {
                dataField: 'assignmentType', text: t('inventory.movements.commons.destination'), headerStyle: { width: '7rem' }
                , formatter: (cell, row) => `${t('inventory.movements.assignmentType.' + cell)}`
            },
            {
                text: t('inventory.movements.commons.assignment'), headerStyle: { width: '7rem' }
                , formatter: (cell, row) => row.assignName
            },
            {
                dataField: 'view',
                text: "",
                isDummyField: true,
                headerClasses: 'header-actions',
                classes: 'row-actions',
                formatter: function (_cell, row, rowIndex) {
                    const isSame = isSameUser(row.user);
                    return (
                        <div key={rowIndex}>
                            <button className="btn-table-actions" onClick={() => { handleShowMoveDetail(row.id) }}>
                                <Tooltip tooltip={'Ver detalles'} id={"tooltipedit-" + row.Id}>
                                    <span aria-hidden="true"><More /></span>
                                </Tooltip>
                            </button>

                            <WithAuth requiredPermission={["GeneralInventory.Edit", "ProtocolInventory.Edit"]}>
                                {isSame && row.status == "pending" && <button className="btn-table-actions" onClick={() => { handleCancelMovement(row) }}>
                                    <Tooltip tooltip={'Eliminar'} id={"tooltipedit-" + row.Id}>
                                        <span aria-hidden="true"><Remove /></span>
                                    </Tooltip>
                                </button>}
                                {isSame && row.status == "pending" && <button className="btn-table-actions" onClick={() => { handleResumeMove(row) }}>
                                    <Tooltip tooltip={'Continuar'} id={"tooltipedit-" + row.Id}>
                                        <span aria-hidden="true"><Edit /></span>
                                    </Tooltip>
                                </button>}
                            </WithAuth>
                        </div>
                    )
                }
            }
        ]

    const All = new EnumValue(0, 'all')
    const inputs = [
        { type: 'select', title: t('commons.type'), name: 'type', options: [All, MoveTypes.AddStock, MoveTypes.AdjustStock], optionName: (op) => t(`inventory.movements.moveType.${op.Name?.toLowerCase()}`), onChange: (e, v) => { }, disabled: false, value: null, col: { sm: 12, md: 2, lg: 2 } },
        { type: 'select', title: t('commons.status'), name: 'status', options: [All, ...MoveStatus.ToArray()], optionName: (op) => t(`inventory.movements.moveState.${op.Name?.toLowerCase()}`), onChange: (e, v) => { }, disabled: false, value: null, col: { sm: 12, md: 2, lg: 2 } },
    ]
    const handleSearch = async (filter) => {
        await navigation.Search({
            includedTypes: filter.type != All ? filter.type?.Name : 'add_stock,adjust_stock',
            status: filter.status != All ? filter.status?.Name : null,
        })
    }

    return (
        <>
            <Card
                content={
                    <Row>
                        <Col sm={12} md={8} lg={10}>
                            <div>
                                <div style={{ width: '100%' }}>
                                    <SearchHeader inputs={inputs} handleSearch={handleSearch} init={{ type: All, status: All }} buttonTitle={t('buttons.apply')} />
                                </div>
                                <div>
                                    <Loader isLoading={isLoading}>
                                        <BootstrapTable
                                            bordered={false}
                                            hover
                                            remote
                                            condensed
                                            keyField='id'
                                            classes="table-spaced table-spaced-small"
                                            wrapperClasses="table-responsive"

                                            pagination={paginationFactory({
                                                page: pagedMoves.PageNumber,
                                                sizePerPage: pagedMoves.PageSize,
                                                totalSize: pagedMoves.TotalCount,
                                                hideSizePerPage: true
                                            })}

                                            onTableChange={handleTableChange}

                                            data={pagedMoves.Values}
                                            columns={columns}
                                            loading={isLoading}
                                            noDataIndication={() => <>{t("commons.noData")}</>}
                                        />
                                    </Loader>
                                </div>
                            </div>
                        </Col>
                        <Col sm={12} md={4} lg={2}>
                            <WithAuth requiredPermission={["GeneralInventory.Edit", "ProtocolInventory.Edit"]}>
                                <Card className="card-actions"
                                    content={<>
                                        <WithAuth requiredPermission={["GeneralInventory.Edit"]}>
                                            <button className={`btn btn-info btn-fill btn-full`} onClick={handleAddStock}>
                                                <Add /> {t('inventory.buttons.addStock')}
                                            </button>
                                        </WithAuth>
                                        <WithAuth requiredPermission={["ProtocolInventory.Edit"]}>
                                            <button className={`btn btn-info btn-fill btn-full`} onClick={handleProtocolAddStock}>
                                                <Add /> {t('inventory.buttons.addStockToProtocol')}
                                            </button>
                                        </WithAuth>
                                        <WithAuth requiredPermission={["GeneralInventory.Edit"]}>
                                            <button className={`btn btn-warning btn-fill btn-full`} onClick={handleAdjustStock}>
                                                <Check /> {t('inventory.buttons.adjustStock')}
                                            </button>
                                        </WithAuth>
                                        <WithAuth requiredPermission={["ProtocolInventory.Edit"]}>
                                            <button className={`btn btn-warning btn-fill btn-full`} onClick={handleProtocolAdjustStock}>
                                                <Check /> {t('inventory.buttons.adjustStockToProtocol')}
                                            </button>
                                        </WithAuth>
                                    </>}
                                />
                            </WithAuth>
                        </Col>
                    </Row>
                }
            />
            <Modal />
        </>
    )
}
