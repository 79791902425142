import axios from '../index';
import { ResponseResult, Url } from "../Common/Util";
import { MoveItemRequest, MoveRequest } from "./Entities/InventoryMovementEntities";

const GetUrl = Url("api/v1/Inventory/Operation");


export default {
    ExcecuteOperation,
    SavePending,
    CancelPending,
    GetPending
}

async function ExcecuteOperation(operation = new MoveRequest()) {
    const response = await axios.post(GetUrl(), { ...operation })
    return new ResponseResult({ ...response.data })
}

async function SavePending(operation = new MoveRequest()) {
    const response = await axios.post(GetUrl('/Pending'), { ...operation })
    return new ResponseResult({ ...response.data })
}

async function CancelPending(moveId){
    const response = await axios.delete(GetUrl(`/Pending/${moveId}`))
    return new ResponseResult({ ...response.data })
}

async function GetPending(moveId) {
    const response = await axios.get(GetUrl(`/Pending/${moveId}`))
    
    const move = new MoveRequest(response.data.value)
    move.items = move.items.map(i => new MoveItemRequest(i))
    return new ResponseResult({ ...response.data, value: move })
}
