import React from 'react';
import InventoryOperationsPage from './InventoryOperationsPage';
import { Col, Nav, NavItem, Row, Tab } from "react-bootstrap";
import InventoryItemsPage from './InventoryItemsPage';
import { useTranslation } from 'react-i18next';

const keys = {
    operations: "operations",
    inventory: "inventory"
}

export function InventoryManagment() {
    const [activeTab, setActiveTab] = React.useState(keys.operations)
    const { t } = useTranslation();

    const handleSelectTab = (key) => setActiveTab(key)

    return <>
        <Tab.Container id="inventory-managment" activeKey={activeTab} onSelect={handleSelectTab} >
            <Row className="clearfix">
                <Col sm={12}>
                    <Nav bsStyle="tabs" >
                        <NavItem eventKey={keys.operations} >{t('inventory.commons.operations')}</NavItem>
                        <NavItem eventKey={keys.inventory}>{t('inventory.commons.inventory')}</NavItem>
                    </Nav>

                </Col>
                <Col sm={12}>
                    <Tab.Content>
                        <Tab.Pane eventKey={keys.operations}>
                            {activeTab == keys.operations && <InventoryOperationsPage />}
                        </Tab.Pane>
                        <Tab.Pane eventKey={keys.inventory}>
                            {activeTab == keys.inventory && <InventoryItemsPage />}
                        </Tab.Pane>
                    </Tab.Content>
                </Col>
            </Row>
        </Tab.Container>
    </>
}
