import axios from './index';

export const getPagingAndOrderParams = (newActiveFilters, searchParams) => {

    if (!newActiveFilters)
        return;

    searchParams.delete('pageIndex'); // se resetea el paginado
    if (newActiveFilters.pagination?.page) {
        searchParams.append('pageIndex', newActiveFilters.pagination.page);
    }
    searchParams.delete('pageSize');
    if (newActiveFilters.pagination?.sizePerPage) {
        searchParams.append('pageSize', newActiveFilters.pagination.sizePerPage);
    }
    searchParams.delete('sortField');
    if (newActiveFilters.sort?.sortField) {
        searchParams.append('sortField', newActiveFilters.sort.sortField);
    }
    searchParams.delete('sortOrder');
    if (newActiveFilters.sort?.sortOrder) {
        searchParams.append('sortOrder', newActiveFilters.sort.sortOrder);
    }

    return searchParams;
}

export const getQueryString = (query, search) => {

    var searchParams = new URLSearchParams(search || '');

    if (!query)
        return searchParams.toString();

    searchParams = getPagingAndOrderParams(query, searchParams);

    if (query.filters) {
        for (const property in query.filters) {

            searchParams.delete(property);
            if (query.filters[property] !== null && query.filters[property] !== undefined) {
                searchParams.append(property, query.filters[property]);
            }
        }
    }

    if (query.query) {
        searchParams.delete("q");
        if (query.query) {
            searchParams.append("q", query.query);
        }
    }

    if (query.onlyDoctor != null && query.onlyDoctor != undefined) {
        searchParams.delete("onlyDoctor");
        searchParams.append("onlyDoctor", query.onlyDoctor);
    }

    if (query.includeDisabled != null && query.includeDisabled != undefined) {
        searchParams.delete("includeDisabled");
        searchParams.append("includeDisabled", query.includeDisabled);
    }

    return searchParams.toString();
}

const transformRequestOptions = (params) => {
    let options = '';
    params.ids.forEach((id) => {
        options += `ids=${id}&`;
    });
    return options;
};

export const getRoleMasterData = () => {
    return axios.get(`api/v1/authorization/masterdata`);
};
export const getRoles = () => {
    return axios.get(`api/v1/authorization`);
};
export const getRolesById = (roles) => {
    return axios.get(`api/v1/authorization/rolesbyid`, {
        params: { ids: roles },
        paramsSerializer: transformRequestOptions,
    });
};
export const getRole = (roleId) => {
    return axios.get(`api/v1/authorization/${roleId}`);
};
export const createRole = (role) => {
    return axios.post(`api/v1/authorization`, role);
};
export const editRole = (roleId, role) => {
    return axios.put(`api/v1/authorization/${roleId}`, role);
};
export const getUsersById = async (userIds = []) => {
    const queryString = userIds.map(id => `Ids=${id}`).join('&');
    return axios.get("api/v1/users/usersbyid?"+ queryString)
}
export const getUsers = async filters => {
    const queryString = getQueryString(filters, window.location.search);
    return axios.get(queryString ? `api/v1/users?${queryString}` : 'api/v1/users');
};
export const getUsersByIdAPI = async users => {
    return axios.get(`api/v1/users/usersbyid`, {
        params: { ids: users },
        paramsSerializer: transformRequestOptions,
    });
};
export const getPractitioners = async filters => {
    const queryString = getQueryString(filters, window.location.search);
    return axios.get(queryString ? `api/v1/users/practitioners?${queryString}` : 'api/v1/users/practitioners');
};
export const getUser = (id) => {
    return axios.get(`api/v1/users/${id}`);
};
export const getUserInfo = (id) => {
    return axios.get(`api/v1/users/${id}/info`);
};
export const getUserInfoSignData = (id) => {
    return axios.get(`api/v1/users/${id}/signData`);
};
export const createUser = (user) => {
    return axios.post('api/v1/users', user);
};
export const resendCreatedUserEmail = (userId) => {
    return axios.post(`api/v1/users/${userId}/resendCreatedEmail`, userId);
};
export const updateUser = (id, user) => {
    return axios.put(`api/v1/users/${id}`, user);
};
export const updateUserInRole = (userId, roleId) => {
    return axios.put(`api/v1/users/${userId}/roles/${roleId}`, null);
};
export const enableUser = (userId) => {
    return axios.put(`api/v1/users/${userId}/enable`, null);
};
export const disableUser = (userId) => {
    return axios.put(`api/v1/users/${userId}/disable`, null);
};
export const changePasswordUser = (userId, password) => {
    return axios.put(`api/v1/users/${userId}/changepassword/${password}`, null);
};
export const resetPassword = (userId) => {
    return axios.put(`api/v1/users/${userId}/resetPassword`, null);
};
export const exportToExcel = (filters) => {
    const queryString = getQueryString(filters, window.location.search);

    return axios.get(queryString ? `api/v1/users/exportToExcel?${queryString}` : 'api/v1/users/exportToExcel', {
        responseType: 'arraybuffer'
    });
};

export const getMasterData = () => {
    return axios.get('api/v1/users/masterdata');
};

export const syncUsers = (tenantId) => {
    return axios.post(`api/v1/users/sync/${tenantId}`, tenantId);
};

export const uploadSign = async (formData) => {
    return axios.post(`api/v1/users/uploadsign`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}

export const getAuditUserRoleAPI = (userId) => {
    return axios.get(`api/v1/users/auditsuserrole/users/${userId}`);
}