import React, { useEffect, useState } from 'react';
import '../../../assets/css/common.css';
import { Remove } from '../../../components/Icons/Icons';
import { Tooltip } from '../../../components/Utils/Tooltips';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { GroupBy } from '../../../api/Common/Util';

//extraColumns example: [{title: 'Title', dataField: 'Name', width: '10%', formmater: (index, row) => "" }]
export function InventoryItemCart({ items, onItemsChange, onDeleteItem, extraColumns = [], splitQuantity = false }) {
    const { t } = useTranslation()
    function handleDeleteItem(row, index) {
        onDeleteItem(row, index);
    }
    function handleItemChange(quantity, row) {
        if (isNaN(quantity) || quantity < 1) return;

        onItemsChange(quantity, row);
    }

    const headers = extraColumns.map((column, index) => <th key={index} style={{ width: column.width }}>{column.title}</th>)

    const extraColumnsValues = (row, index) => extraColumns.map((column) => {
        return <td key={index}>{column.formatter == null ? row[column.dataField] : column.formatter(index, row)}</td>
    })

    return (
        <table className='table table-hover table-condensed table-sm table-spaced-small'>
            <thead>
                <tr>
                    <th style={{ width: '25%' }}>{t('commons.description')}</th>
                    <th style={{ width: '20%' }}>{t('inventory.commons.lotNumber')}</th>
                    {!splitQuantity && <th style={{ width: '10%' }}>{t('commons.quantity')}</th>}
                    {headers}
                    <th style={{ width: '10%' }}></th>
                </tr>
            </thead>
            <tbody>
                {items
                    .map((row, index) => (
                        <>
                            <tr key={index}>
                                <td>{row.item.presentation}</td>
                                <td><strong>L:</strong> {`${row.release.lotNumber} - `} <strong>V:</strong> {` ${moment(row.release.expirationDate).format('DD-MM-YYYY')}`}</td>
                                {!splitQuantity && <td><input className='form-control form-control-sm text-center' type='number' min="1" value={row.quantity} onChange={(e) => handleItemChange(e.target.value, row)} /></td>}
                                {extraColumnsValues(row, index)}
                                <td>
                                    <div>
                                        <button className="btn-table-actions" onClick={() => handleDeleteItem(row, index)}>
                                            <Tooltip tooltip={'Eliminar item'} id={"tooltipedit-" + row.release.lotNumber}>
                                                <span aria-hidden="true"><Remove /></span>
                                            </Tooltip>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </>
                    ))}
            </tbody>
        </table>
    )
}


const GroupItem = (items) => {
    const groups = GroupBy(items, item => item.release.lotNumber)
    return Array.from(groups.values())
        .map((group) => {
            return {
                presentation: group[0].item.presentation,
                quantity: group.length,
                release: group[0].release,
                details: group
            }
        })
}

export function InventoryItemCartExpandable({ items, onItemsChange, onDeleteItem, extraColumns = [], splitQuantity = false }) {
    const { t } = useTranslation()
    const [expanded, setExpanded] = useState(null)
    const GroupedItemsList = GroupItem(items)

    function handleDeleteItem(row, index) {
        onDeleteItem(row, index);
    }
    function handleItemChange(quantity, row) {
        if (isNaN(quantity) || quantity < 1) return;

        onItemsChange(quantity, row);
    }

    const headers = extraColumns.map((column, index) => <th key={index} style={{ width: column.width }}>{column.title}</th>)

    const extraColumnsValues = (row, index) => extraColumns.map((column) => <td key={index}>{column.formatter == null ? row[column.dataField] : column.formatter(row, index)}</td>)

    const toggleContent = (index) => {
        if (expanded != index) {
            setExpanded(index)
        } else {
            setExpanded(null)
        }
    }

    function renderPresentation(presentation) {
        if (presentation?.toLowerCase().includes('protocol'))
            return t('inventory.movements.assignmentType.protocol')
        return presentation
    }

    return (
        <table className='table table-hover table-condensed table-sm table-spaced-small'>
            <thead>
                <tr>
                    <th style={{ width: '25%' }}>{t('commons.description')}</th>
                    <th style={{ width: '20%' }}>{t('inventory.commons.lotNumber')}</th>
                    <th style={{ width: '10%' }}>{t('commons.quantity')}</th>
                </tr>
            </thead>
            <tbody>
                {GroupedItemsList
                    .map((row, index) => (
                        <React.Fragment key={index}>
                            <tr key={`item-${index}`} onClick={() => toggleContent(index)} style={{ cursor: 'pointer' }}>
                                <td>{renderPresentation(row.presentation)}</td>
                                <td><strong>L:</strong> {`${row.release.lotNumber} - `} <strong>V:</strong> {` ${moment(row.release.expirationDate).format('DD-MM-YYYY')}`}</td>
                                <td>{row.quantity}</td>
                            </tr>
                            {expanded == index && <tr key={`content-${index}`}>
                                <td colSpan={100}>
                                    <table className='table table-condensed table-sm table-spaced-small'>
                                        <thead>
                                            <tr>
                                                {headers}
                                                <th style={{ width: '6.25rem' }}></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {row.details.map((detail, detailIndex) => (<tr key={`item_content-${detailIndex}`}>
                                                {extraColumnsValues(detail, detailIndex)}
                                                <td>
                                                    <div>
                                                        <button className="btn-table-actions" onClick={() => handleDeleteItem(detail, detailIndex)}>
                                                            <Tooltip tooltip={'Eliminar item'} id={"tooltipedit-" + row.release.lotNumber}>
                                                                <span aria-hidden="true"><Remove /></span>
                                                            </Tooltip>
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>)
                                            )}
                                        </tbody>
                                    </table>
                                </td>
                            </tr >}
                        </React.Fragment>
                    ))}
            </tbody>
        </table >
    )
}