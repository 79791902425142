import { useState } from "react";
import { PaginationRequest, PaginationResult } from "../../../api/Common/Util";
import InventoryItemsService from "../../../api/Inventory/InventoryItemsService";
import useLoading from "../../../components/Common/useLoading";
import InventoryStockService from "../../../api/Inventory/InventoryStockService";

const propsTemplate = () => { return { ...new PaginationRequest(), protocolId: null } }
export default function useInventoryItems({ preload = false, includeStock = false, requestProps = propsTemplate() } = {}) {
    const [pagedItems, setPagedItems] = useState(new PaginationResult())
    const [isLoading, useLoad, reload] = useLoading({
        onLoad: async () => {
            if (!preload) return
            console.log(requestProps)
            await FindItems({ ...requestProps })
        },
        onError: (error) => setPagedItems(new PaginationResult())
    })

    const Navigation = {
        ToPage: async ({ page }) => useLoad(async () => {
            const request = pagedItems.ToPage(page);

            if (request == null) return
            await FindItems({ ...request })
        }),
        Find: async (props = propsTemplate()) => useLoad(async () => {
            const newRequest = { ...requestProps, ...props };
            await FindItems(newRequest)
        }),
        Clear: () => setPagedItems(new PaginationResult()),
        Reload: reload
    }

    async function FindItems(props) {
        const result = await InventoryItemsService.Find(props)
        result.SortColumn = requestProps.sortColumn
        result.SortOrder = requestProps.sortOrder

        if (includeStock) {
            const ids = result.Values.map(item => item.id)
            const stockMap = (await InventoryStockService.Find({ itemIds: ids }))
                .Value
                .reduce((map, item) => { map[item.itemId] = item.stock; return map; }, {});

            result.Values.forEach(item => {
                item.stock = stockMap[item.id] ?? 0
            })
        }
        setPagedItems(result)
    }

    return [pagedItems, isLoading, Navigation]
}