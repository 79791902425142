import React from "react";
import { Modal, Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

export function ConfirmationModal({ state: { show, onClose, onConfirmation, configuration: { title, textContent, buttonText, buttonType } } }) {
    const { t } = useTranslation();

    if (!show) return null

    return (
        <Modal show={show} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title >{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <span>{textContent}</span>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onClose}>{t('buttons.close')}</Button>
                <button type="button" className={`btn btn-${buttonType} btn-right`} onClick={onConfirmation}>{buttonText}</button>
            </Modal.Footer>
        </Modal >
    )
}  