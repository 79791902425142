import { useEffect, useState } from "react";
import { MoveItemRequest, MoveRequest } from "../../../api/Inventory/Entities/InventoryMovementEntities";
import useLoading from "../../../components/Common/useLoading";
import InventoryOperationService from "../../../api/Inventory/InventoryOperationService";
import { useAuthorization } from "../../../components/Authorization/useAuthorization";

export default function useInventoryCart(moveId, moveType, assignmentType, assignId = null, configuration = {
    maxQuantityPerRow: 9999,
}) {
    const [isAuthenticated, user, isAuthorized] = useAuthorization();
    const [state, setState] = useState(new MoveRequest({
        moveType: moveType,
        assignment: {
            assignmentType: assignmentType,
            assignId: assignId
        },
    }))
    const [isLoading, withLoad, doReload] = useLoading()

    async function InitializeMovement(moveId, userId) {
        await withLoad(async () => {
            
            if (userId == undefined || userId == "" || userId == " ") throw new Error("Invalid userId not found");


            const nextState = isNaN(moveId) || moveId == 0
                ? { ...state, userId: userId, confirmationUserId: userId }
                : { ...((await InventoryOperationService.GetPending(moveId)).Value), confirmationUserId: userId }
            setState(nextState)
        })
    }

    useEffect(() => {
        if (user) {
            InitializeMovement(moveId, user.sub)
        }
    }, [isAuthenticated])

    const AddItems = (cartItems) => {
        const itemRequest = cartItems.map(cartItem => new MoveItemRequest({
            id: 0,
            item: {
                externalId: cartItem.item.externalId,
                origin: cartItem.item.origin,
                type: cartItem.item.type,
                presentation: cartItem.item.presentation,
                inventoryItemId: cartItem.item.inventoryItemId
            },
            release: cartItem.release,
            quantity: cartItem.quantity,
            assignment: cartItem.assignment
        }))

        setState({
            ...state,
            items: [...state.items, ...itemRequest]
        })
    }

    const AddItem = (item, release, quantity, assignment) => {
        const current = state.items.find(i => i.itemId == item.inventoryItemId && i.release.lotNumber == release.lotNumber)

        if (current) {
            current.quantity = (current.quantity * 1) + (quantity * 1);
            setState({
                ...state,
                items: state.items
            })
            return
        }
        const itemRequest = new MoveItemRequest({
            id: 0,
            item: {
                externalId: item.externalId,
                origin: item.origin,
                type: item.type,
                presentation: item.presentation,
                inventoryItemId: item.inventoryItemId
            },
            release,
            quantity,
            assignment
        })
        setState({
            ...state,
            items: [...state.items, itemRequest]
        })
    }

    const UpdateQuantity = (item, quantity) => {
        const currentItem = state.items.find(i => i.uniqueId == item.uniqueId)
        if (currentItem) {
            currentItem.quantity = quantity;
        }
        setState({
            ...state,
            items: state.items
        })
    }

    const ConfirmQuantities = (item, confirmation) => {
        const currentItem = state.items.find(i => i.uniqueId == item.uniqueId)
        if (currentItem.quantity < confirmation || confirmation < 0)
            throw new Error("Cantidad de confirmación no válida")

        if (currentItem)
            currentItem.confirmedQuantity = confirmation;

        setState({
            ...state,
            items: state.items
        })
    }

    const UpdateItem = (item) => {
        const arr = Array.from(state.items)
        const index = state.items.findIndex(i => i.uniqueId == item.uniqueId)
        arr[index] = { ...arr[index], ...item }
        setState({
            ...state,
            items: arr
        })
    }

    const RemoveItem = (item) => {
        setState({
            ...state,
            items: state.items.filter((i) => i.uniqueId != item.uniqueId)
        });
    };

    const SetAssignId = (assignId) => {
        setState({
            ...state,
            assignment: {
                ...state.assignment,
                assignId
            }
        })
    }

    const CartActions = {
        AddItem,
        AddItems,
        RemoveItem,
        UpdateQuantity,
        UpdateItem,
        SetAssignId
    }


    return [state, isLoading, CartActions]
}