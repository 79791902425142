import axios from '../index';
import { ResponseResult, Url } from "../Common/Util";

const GetUrl = Url("api/v1/Inventory/Medications");

export default {
    GetById,
    Find
}

async function GetById(externalId) {
    const response = await axios.get(GetUrl(`/${externalId}`))
    return new ResponseResult({ ...response.data })
}
async function Find({term, onlyLocal, protocolId = null, pageSize = 10, page = 1 }){
    const response = await axios.get(GetUrl('/search'), {
        params: { term, onlyLocal, protocolId, pageSize, page }
    })
    return new ResponseResult({ ...response.data })
}

