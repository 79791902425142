import React from 'react';
import { useState } from "react";
import { Col, Row } from 'react-bootstrap';
import { CustomSelect } from '../CustomControls/CustomSelect';
import { Button } from '../CustomControls/Button';

export default function SearchHeader({ inputs, handleSearch, init = null, buttonTitle = null }) {

    const [filterState, setState] = useState(init)

    const InputWrapper = ({ index, id, col, children, title }) => {
        return (
            <Col key={index} sm={col.sm} md={col.md} lg={col.lg} >
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <label htmlFor={id}>{title}</label>
                    {children}
                </div>
            </Col>
        )
    }

    const render = () => inputs
        .map((input, index) => {
            switch (input.type) {
                case "select":
                    return (<InputWrapper key={index + 'wrapper'} id={input.id ?? input.name} col={input.col} title={input.title}>
                        <CustomSelect key={index + 'input'} name={input.name} value={filterState[input.name]} options={input.options} onChange={(e, v) => {
                            setState({ ...filterState, [input.name]: v });
                            if (input.onchange != null)
                                input.onChange(e, v);
                        }} getOptionLabel={(option) => input.optionName != null ? input.optionName(option) : option.Name} getOptionValue={(option) => option?.Value} isDisabled={input.disabled} />
                    </InputWrapper>)
            }
        });

    const onSearch = () => {
        if (handleSearch != null)
            handleSearch(filterState)
    }

    return (
        <div style={{ width: '100%' }}>
            <Row style={{ display: 'flex', alignItems: 'end' }}>
                {render()}
                <Col sm={12} md={2}>
                    <Button onClick={onSearch}>{buttonTitle}</Button>
                </Col>
            </Row>
        </div >
    )
}