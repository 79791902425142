import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import useMove from '../Hooks/useMove';
import Card from "../../../components/Cards/Card";
import { Col, Row } from 'react-bootstrap';
import { Field } from '../../../components/Common/Field/Field';
import { useTranslation } from 'react-i18next';
import Loader from '../../../components/Common/Loader';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import moment from 'moment';

export function MoveDetailPage({ match: { params } }) {
    const history = useHistory();
    const { t } = useTranslation();
    const { moveId } = params
    const [move, isLoading] = useMove(moveId);

    if (move == null || move.id == 0) return (<Loader isLoading={true}></Loader>)

    const handleCancel = () => {
        history.push(`/admin/inventory`);
    }

    const columns = [
        { dataField: 'presentation', text: t('commons.description'), headerStyle: { width: '7rem' } },
        { dataField: 'lotNumber', text: t('inventory.commons.lotNumber'), headerStyle: { width: '3rem' } },
        { dataField: 'quantity', text: t('commons.quantity'), headerStyle: { width: '3rem' } }
    ]

    if (move.assignmentType == 'protocol')
        columns.push({ dataField: 'assignment.kitNumber', text: t('inventory.commons.kitNumber'), headerStyle: { width: '3rem' } })

    return (<>
        <Card
            content={<>
                <Loader isLoading={isLoading}>
                    <div className='text-center'>
                        <label className="info-title info-title-lg">{t('inventory.commons.movement')}</label>
                    </div>
                    <Row>
                        <Col md={4} sm={12}>
                            <div className="info" style={{ justifyContent: 'space-between!important' }}>
                                <Field title={'ID'} value={move.id} spaceType={'between'} />
                                <Field title={t('commons.type')} spaceType={'between'} value={t(`inventory.movements.moveType.${move.moveType}`)} />
                                <Field title={t('commons.status')} spaceType={'between'} value={t(`inventory.movements.moveState.${move.status}`)} />
                            </div>
                        </Col>
                        <Col md={4} sm={12}>
                            <div className="info" style={{ justifyContent: 'space-between!important' }}>
                                <Field title={t('commons.user')} spaceType={'between'} value={move.user?.fullName ?? "-"} />
                                <Field title={t('commons.date')} spaceType={'between'} value={moment(move.createAt).format("DD/MM/YYYY HH:mm")} />
                            </div>
                        </Col>
                        <Col md={4} sm={12}>
                            <div className="info" style={{ justifyContent: 'space-between!important' }}>
                                <Field title={t('inventory.movements.commons.destination')} spaceType={'between'} value={t(`inventory.movements.assignmentType.${move.assignmentType}`)} />
                                <Field title={t('inventory.movements.commons.assignment')} spaceType={'between'} value={move.assignName} />
                            </div>
                        </Col>
                        <Col sm={12}>
                            <div>
                                <hr />
                                <div className='text-center' style={{ marginTop: "1rem" }}>
                                    <strong className='info-label'>{t('inventory.commons.detail')}</strong>
                                </div>
                                <div style={{ height: '300px', overflow: 'hidden auto' }}>
                                    <BootstrapTable
                                        bordered={false}
                                        hover
                                        remote
                                        keyField='id'
                                        pagination={paginationFactory({
                                            sizePerPage: move?.items?.length ?? 0,
                                            totalSize: move?.items?.length ?? 0,
                                            hideSizePerPage: true
                                        })}
                                        onTableChange={() => { }}
                                        classes="table-spaced table-spaced-small"
                                        wrapperClasses="table-responsive"
                                        data={move.items}
                                        columns={columns}
                                        loading={isLoading}
                                        noDataIndication={() => <>{t("commons.noData")}</>}
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={1} sm={2} smOffset={11}>
                            <button className="btn btn-secondary" type="button" onClick={handleCancel}>{t('buttons.back')}</button>
                        </Col>
                    </Row>
                </Loader>
            </>}
        />
    </>)
}