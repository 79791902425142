import React from 'react';
import Card from '../../../components/Cards/Card';
import BootstrapTable from 'react-bootstrap-table-next';
import Loader from '../../../components/Common/Loader';
import { Col, Nav, NavItem, Row, Tab } from "react-bootstrap";
import { PaginationRequest } from '../../../api/Common/Util';
import useInventoryItems from '../Hooks/useInventoryItems';
import { useTranslation } from 'react-i18next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Tooltip } from '../../../components/Utils/Tooltips';
import { useHistory } from "react-router-dom"
import { More } from '../../../components/Icons/Icons';
import { CustomSearchBox } from '../../../components/CustomControls/CustomSearchBox';

export default function InventoryItemsPage() {
    const { t } = useTranslation();
    const history = useHistory();

    const [pagedItems, isLoading, navigation] = useInventoryItems({
        preload: true,
        includeStock: true,
        requestProps: new PaginationRequest({
            pageSize: 10,
            pageNumber: 1,
            sortColumn: 'presentation',
            sortOrder: 'asc'
        })
    })


    const handleShowDetail = (itemId) => {
        history.push('/admin/inventory/items/' + itemId)
    }

    const columns = [
        { dataField: 'id', text: 'ID', headerStyle: { width: '5rem' } },
        { dataField: 'presentation', text: 'Presentación', headerStyle: { width: '15.625rem' } },
        { dataField: 'stock', text: 'Stock', headerStyle: { width: '5rem' } },
        {
            dataField: 'view',
            text: "",
            isDummyField: true,
            headerClasses: 'header-actions',
            classes: 'row-actions',
            formatter: function (_cell, row, rowIndex) {
                return (
                    <div key={rowIndex}>
                        <button className="btn-table-actions" onClick={() => { handleShowDetail(row.id) }}>
                            <Tooltip tooltip={'Ver detalles'} id={"tooltipedit-" + row.Id}>
                                <span aria-hidden="true"><More /></span>
                            </Tooltip>
                        </button>
                    </div>
                )
            }
        }
    ]

    const handleSearch = async (q) => {
        await navigation.Find({ search: q })
    }

    return (<>
        <Card
            header={<Row>
                <Col sm={12} md={4}>
                    <CustomSearchBox handleSearch={handleSearch} placeholder={t('Buscar medicamento')} />
                </Col>
            </Row>}
        content={
                <Loader isLoading={isLoading}>
                    <BootstrapTable
                        bordered={false}
                        hover
                        remote
                        condensed
                        keyField='id'
                        classes="table-spaced table-spaced-small"
                        wrapperClasses="table-responsive"

                        pagination={paginationFactory({
                            page: pagedItems.PageNumber,
                            sizePerPage: pagedItems.PageSize,
                            totalSize: pagedItems.TotalCount,
                            hideSizePerPage: true
                        })}

                        data={pagedItems.Values}
                        columns={columns}
                        loading={isLoading}
                        noDataIndication={() => <>{t("commons.noData")}</>}
                    />
                </Loader>
            }
        />
    </>)
}
