import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal, Button } from 'react-bootstrap'
import useReleasesStock from "../Hooks/useReleaseStock";
import { AssignmentType, ReleaseStockState } from "../../../api/Inventory/Entities/InventoryEntities";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import Loader from "../../../components/Common/Loader";
import useRelease from "../Hooks/useRelease";
import moment from "moment";
import { EnumValue } from "../../../api/Freezer/Entities/Utils";
import SearchHeader from "../../../components/Common/SearchHeader";

const defaulFilter = (releaseId) => {
    return (assignmentType = null, includedStates = null, sortColumn = 'createAt', sortOrder = null) => {
        return {
            sortColumn: sortColumn,
            sortOrder: sortOrder,
            releaseId: releaseId,
            assignmentType: assignmentType,
            includedStates: includedStates ?? ReleaseStockState.ToArray(),
            pageNumber: 1,
        }
    }
}


export default function ReleaseStockModal({ value, onClose }) {
    const { t } = useTranslation();
    const requestProps = defaulFilter(value)
    const [release, isReleaseLoading] = useRelease(value)
    const [pagedStock, isStockLoading, navigation] = useReleasesStock(requestProps())

    function handleTableChange(type, { page, sizePerPage, sortField, sortOrder }) {
        navigation.ToPage({
            page
        })
    }
    const columns = [
        {
            text: t('inventory.commons.assigmentType'),
            headerStyle: { width: '10rem' },
            formatter: (_cell, row, rowIndex) => t(`inventory.movements.assignmentType.${row.assignment.assignmentType.toLowerCase()}`)
        },
        {
            text: t('inventory.commons.assignment'),
            headerStyle: { width: '15rem' },
            formatter: (_cell, row, rowIndex) => row.assignName
        },
        { dataField: 'state', text: t('commons.state'), headerStyle: { width: '10rem' }, formatter: (cell, row) => t(`inventory.stock.state.${row.state.toLowerCase()}`) },
        { text: t('commons.creationDate'), headerStyle: { width: '10rem' }, formatter: (cell, row) => moment(row.createAt).format('DD/MM/YYYY') },
        { dataField: 'assignment.kitNumber', text: t('inventory.commons.kitNumber'), headerStyle: { width: '6rem' } }
    ]

    const All = new EnumValue(0, 'all')
    const inputs = [
        { type: 'select', title: t('commons.type'), name: 'type', options: [All, ...AssignmentType.ToArray()], optionName: (op) => t(`inventory.movements.assignmentType.${op.Name.toLowerCase()}`), onChange: (e, v) => { }, disabled: false, value: null, col: { sm: 12, md: 3, lg: 3 } },
        { type: 'select', title: t('commons.status'), name: 'status', options: [All, ...ReleaseStockState.ToArray()], optionName: (op) => t(`inventory.stock.state.${op.Name.toLowerCase()}`), onChange: (e, v) => { }, disabled: false, value: null, col: { sm: 12, md: 3, lg: 3 } },
    ]

    const handleSearch = async (filter) => {
        const { type, status } = filter

        const request = requestProps(
            type.Name == 'all' ? null : type,
            status.Name == 'all' ? null : [status])
        await navigation.Search(request)
    }

    return (
        <Modal show={!isReleaseLoading} onHide={onClose} backdrop='static' bsSize="large">
            <Modal.Header closeButton>
                <Modal.Title>{t('inventory.commons.inventoryDetail')} - {release?.lotNumber}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <SearchHeader inputs={inputs} handleSearch={handleSearch} init={{ type: All, status: ReleaseStockState.Available }} buttonTitle={t('buttons.apply')} />
                </div>
                <Loader isLoading={isStockLoading}>
                    <BootstrapTable
                        bordered={false}
                        hover
                        remote
                        condensed
                        keyField='id'
                        classes="table-spaced table-spaced-small"
                        wrapperClasses="table-responsive"
                        pagination={paginationFactory({
                            page: pagedStock.PageNumber,
                            sizePerPage: pagedStock.PageSize,
                            totalSize: pagedStock.TotalCount,
                            hideSizePerPage: true
                        })}
                        onTableChange={handleTableChange}
                        data={pagedStock.Values}
                        columns={columns}
                        loading={isStockLoading}
                        noDataIndication={() => <>{t("commons.noData")}</>}
                    />
                </Loader>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onClose}>{t('buttons.close')}</Button>
            </Modal.Footer>
        </Modal >
    )
}   