import React from 'react';
import { MoveTypes } from '../../../api/Inventory/Entities/InventoryMovementEntities';
import { InventoryProtocolMoveForm } from '../Shared/InventoryProtocolMoveForm';
import { useTranslation } from 'react-i18next';
import { Assigments } from '../../../api/Inventory/Entities/InventoryEntities';

export function AdjustStockFormByProtocolPage({ match: { params } }) {
    const { t } = useTranslation();

    return (<>
        <InventoryProtocolMoveForm
            title={t('inventory.movements.commons.adjustEntryByProtocol')}
            moveId={params.moveId ?? 0}
            moveType={MoveTypes.AdjustStock.Name}
            assignmentType={Assigments.Protocol}
            onlyLocalInventory={true} />
    </>)
}