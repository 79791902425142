import axios from "axios";
import { ResponseResult, Url } from "../Common/Util";
import { Freezer } from "./Entities/Freezer";
import { ProtocolFreezerConfiguration } from "./Entities/ProtocolFreezerConfiguration";

const GetUrl = Url("api/v1/Freezer/Protocol");

export default {
    Configure,
    GetConfigById,
    GetUnassignedFreezers,
    GetProtocolConfigs
}

async function Configure(protocol) {
    const obj = { ...protocol, protocolId: protocol.Id };
    const response = await axios.post(GetUrl('/Configure')
        , { ...protocol, protocolId: protocol.Id })

    return new ResponseResult(response.data);
}

async function GetConfigById(protocolId) {
    const response = await axios.get(GetUrl(`/${protocolId}`))
    return new ResponseResult({ ...response.data, value: new ProtocolFreezerConfiguration({ ...response.data.value }) })
}

async function GetUnassignedFreezers(protocolId) {
    const response = await axios.get(GetUrl(`/${protocolId}/Unassigned`))
    var freezers = response.data.value.map(f => new Freezer(f));
    return new ResponseResult({ ...response.data, value: [...freezers] })
}

async function GetProtocolConfigs() {
    const response = await axios.get(GetUrl(`/Find`))
    const protocols = response.data.value.map(p => new ProtocolFreezerConfiguration(p));
    return new ResponseResult({ ...response.data, value: protocols })
}