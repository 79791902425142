import { useAuthorization } from "../../../components/Authorization/useAuthorization";

export function useSameUser() {
    const [isAuthenticated, sessionUser, isAuthorized] = useAuthorization();

    const isSameUser = (user) => {
        return isAuthenticated && sessionUser.sub == user?.userId;
    }

    return [isSameUser];
}