import axios from "axios";
import { PaginationRequest, PaginationResult, ResponseResult, Url } from "../Common/Util";
import { Freezer } from "./Entities/Freezer";
import { IotDevice } from "./Entities/IotDevice";

const GetUrl = Url("api/v1/Freezer");

export default {
    Find,
    Create,
    Update,
    Delete,
    GetById,
    GetAvailableDevices
}

async function Find({ search, pageNumber, sortOrder, pageSize, withDevices = false, freezerIds = [] } = {}){
    const response = await axios.post(GetUrl('/Find'), { search, pageNumber, sortOrder, pageSize, withDevices, freezerIds });
    const data = new PaginationResult(response.data);
    data.Values = data.Values.map(f => new Freezer(f))
    return data;
}

async function Create(freezer) {
    const response = await axios.post(GetUrl(), { ...freezer, State: freezer.State.Name, Type: freezer.Type.Name });
    return new ResponseResult({ ...response.data, value: new Freezer(response.data.value) });
}
async function Update(freezer) {
    const response = await axios.put(GetUrl(), { ...freezer, State: freezer.State.Name, Type: freezer.Type.Name });
    return new ResponseResult({ ...response.data, value: new Freezer(response.data.value) });
}
async function Delete(freezerId) {
    const response = await axios.delete(GetUrl(`/${freezerId}`));
    return new ResponseResult({ ...response.data })
}

async function GetById(id) {
    if (id == null || id == '') throw new Error('The ID can not be empty')
    const response = await axios.get(GetUrl(`/${id}`))
    return new Freezer(response.data.value);
}


async function GetAvailableDevices() {
    const response = await axios.get(GetUrl("/AvailableDevices"))
    const result = new ResponseResult(response.data);
    result.Value.map(d => new IotDevice(d))
    return result
}

