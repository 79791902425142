import React, { useEffect, useRef, useState } from 'react';
import '../../../assets/css/common.css';
import { Grid, Row, Col, FormGroup, ControlLabel, FormControl } from 'react-bootstrap'
import { SuggestionComponent } from './SuggestionComponent';
import { CustomDate } from '../../../components/CustomControls/CustomDateTime';
import moment from 'moment';
import useReleases from "../Hooks/useReleases";
import InventoryReleaseService from '../../../api/Inventory/InventoryReleaseService';
import { useTranslation } from 'react-i18next';

const initialState = {
    selectedItem: null,
    selectedRelease: null,
    quantity: 1,
}

export function InventoryForm({ handleSubmit, handleSearchItem, disabled }) {
    const { t } = useTranslation()
    const [state, setState] = useState(initialState);
    const [isLoading, navigation] = useReleases()
    const medicationRef = useRef();
    const lotRef = useRef();
    const expirationDateInputRef = useRef();
    const expirationDateRef = useRef();
    const quantityRef = useRef();

    async function fetchItems(value) {
        const result = await handleSearchItem(value);
        return result;
    }


    function handleItemClean() {
        setState({ ...state, selectedItem: null, selectedRelease: null, quantity: 1 })
    }

    function handleItemChange(e) {
        if (e == null || e == undefined) return;

        setState({ ...state, selectedItem: e })
        setFocus(lotRef.current)
    }

    async function fetchReleases(value) {
        if (state.selectedItem == null || state.selectedItem.inventoryItemId == null) return []

        const result = await InventoryReleaseService.Find({ page: 1, pageSize: 10, itemId: state.selectedItem.inventoryItemId, search: value, includeStock: true })
        return result.Values
    }

    function handleReleaseChange(e) {
        setState({ ...state, selectedRelease: e })
        if (e != null && e.id > 0)
            setFocus(quantityRef.current)
    }
    function handleReleaseKeyDown(e) {
        if (e.key == 'Enter' || e.keyCode == 13)
            setFocus(expirationDateInputRef.current)
    }

    function handleReleaseClean() {
        setState({ ...state, selectedRelease: null, quantity: 1 })
    }

    function isFormValid() {
        return state.selectedItem !== null
            && state.selectedRelease !== null
            && state.quantity > 0
            && state.selectedRelease.expirationDate !== null
    }

    function handleQuantityChange(e) {
        const value = e.target.value;
        if (isNaN(value) || value < 1) return;

        setState({ ...state, quantity: value })
    }

    const onHandleFromChange = (e, v) => {
        const date = v.toDate();

        setState({ ...state, selectedRelease: { ...state.selectedRelease, expirationDate: date } })
        expirationDateRef.current._closeCalendar()
        setFocus(quantityRef.current)
    }

    function handleFormSubmit(e) {
        e.preventDefault();

        if (!isFormValid()) return
        handleSubmit(state)
        handleItemClean()
        setFocus(medicationRef.current)
    }

    const isNewItem = () => (state.selectedItem != null && (state.selectedItem.inventoryItemId == undefined || state.selectedItem.inventoryItemId < 1))

    function setFocus(input) {
        input.disabled = false
        input.focus()
    }

    return (<form onSubmit={handleFormSubmit} autoComplete="off">
        <FormGroup>
            <SuggestionComponent
                inputRef={medicationRef}
                value={state.selectedItem}
                disabled={disabled}
                fetchSuggestions={fetchItems}
                renderSuggestion={(item) => `${item.presentation} (${item.drug})`}
                renderValue={(selected) => selected.presentation}
                onDefaultValue={(value) => { return null }}
                noResultsMessage="Sin resultados"
                placeholder={t('inventory.commons.writeNameOrCB')}
                loadingMessage={`${t('commons.searching')}...`}
                onChangeValue={handleItemChange}
                onClean={handleItemClean}
                helpText={isNewItem() ? "* Nuevo item de inventorio" : undefined}
                helpTextType={"warning"}
            />
        </FormGroup>
        <hr />
        <div className='input-group-container'>
            <FormGroup style={{ width: "100%" }}>
                <SuggestionComponent
                    inputRef={lotRef}
                    onKeyDown={handleReleaseKeyDown}
                    value={state.selectedRelease}
                    fetchSuggestions={fetchReleases}
                    renderSuggestion={(item) => `L: ${item.lotNumber} - V: ${moment(item.expirationDate).format('DD-MM-YYYY')} - stock: ${item.stockQuantity}`}
                    renderValue={(selected) => selected.lotNumber}
                    onDefaultValue={(value) => { return { id: 0, lotNumber: value, expirationDate: null } }}
                    noResultsMessage="Sin resultados"
                    placeholder={t("inventory.commons.enterLot")}
                    loadingMessage={`${t('commons.searching')}...`}
                    disabled={state.selectedItem == null || disabled}
                    onChangeValue={handleReleaseChange}
                    onClean={handleReleaseClean}
                    helpText={isNewItem() || state.selectedItem != null && state.selectedRelease == null ? "* Nuevo lote" : undefined}
                    helpTextType={"warning"}
                />
            </FormGroup>

            <FormGroup style={{ width: "100%" }} >
                <CustomDate
                    dateRef={expirationDateRef}
                    inputRef={expirationDateInputRef}
                    name={`expirationDate`}
                    onChange={onHandleFromChange}
                    onBlur={() => { }}
                    value={state.selectedRelease?.expirationDate ?? null}
                    defaulValue={null}
                    dateFormat="DD/MM/YYYY"
                    disabled={state.selectedItem == null || (state.selectedRelease && state.selectedRelease.id > 0) || disabled}
                    inputProps={{ placeholder: t('commons.expirationDate') }}
                />
            </FormGroup>
        </div>
        <hr />
        <div style={{ display: "flex", justifyContent: 'end' }} >
            <div style={{ display: 'flex', flexDirection: "column", width: "25%", marginRight: '1rem' }}>
                <FormGroup style={{ marginBottom: '0px' }} >
                    <input ref={quantityRef} className='form-control' 
                    placeholder="Cantidad" type="number" min="1" name={'item.Quantity'} 
                    disabled={disabled} value={state.quantity} onChange={handleQuantityChange} autoComplete="off" />
                </FormGroup>
            </div>
            <div style={{ alignSelf: 'self-end' }}>
                <button type="submit" className='btn btn-success' disabled={!isFormValid()}>{t('buttons.add')}</button>
            </div>
        </div>
    </form>)
}