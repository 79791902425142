import React, { useContext, useEffect, useState } from 'react';
import Card from '../../../components/Cards/Card';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '../../../components/Utils/Tooltips';
import { Add, Check, Export, Publish, Save } from '../../../components/Icons/Icons';
import Loader from '../../../components/Common/Loader';
import moment from "moment";
import BootstrapTable from "react-bootstrap-table-next";
import useMoves from '../../Inventory/Hooks/useMoves';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { useHistory } from "react-router-dom"
import ProtocolContext from '../ProtocolContext/ProtocolContext';
import { MoveStatus, MoveTypes } from '../../../api/Inventory/Entities/InventoryMovementEntities';
import { AssignmentType } from '../../../api/Inventory/Entities/InventoryEntities';

export default function ProtocolInventoryTabContainer({ }) {
    const { t } = useTranslation();
    const history = useHistory();
    const protocolContext = useContext(ProtocolContext);
    const [pagedMoves, isLoading, navigation] = useMoves({
        pageSize: 10,
        pageNumber: 1,
        sortColumn: 'createat',
        sortOrder: 'desc',
        includedTypes: MoveTypes.ProtocolDispense.Name,
        assignmentTypes: AssignmentType.Protocol.Name,
        assignId: protocolContext.protocolId
    });

    function handleTableChange(type, { page, sizePerPage, sortField, sortOrder }) {
        navigation.ToPage({
            page
        })
    }

    function handleDispenseRequest() {
        history.push(`/admin/protocols/${protocolContext.protocolId}/inventory/request/${protocolContext.protocolId}`)
    }

    function handleDispenseConfirm(dispenseId) {
        history.push(`/admin/protocols/${protocolContext.protocolId}/inventory/${dispenseId}/confirm`)
    }
    function handleDispenseDispatch(dispenseId) {
        history.push(`/admin/protocols/${protocolContext.protocolId}/inventory/${dispenseId}/dispatch`)
    }

    const columns = [
        {
            dataField: 'user.fullName', text: 'Nombre', headerStyle: { width: '15rem' }
        },
        {
            text: t('commons.date'), headerStyle: { width: '6rem' }
            , formatter: function (_cell, move, rowIndex) {
                return moment(move.createAt).format("DD/MM/YYYY HH:mm");
            }
        },
        {
            dataField: 'status', text: t('commons.state'), headerStyle: { width: '7rem' }
            , formatter: (cell, row) => t(`inventory.movements.moveState.${cell}`)
        },
        {
            dataField: 'view',
            text: "",
            isDummyField: true,
            headerClasses: 'header-actions',
            classes: 'row-actions',
            formatter: function (_cell, row, rowIndex) {
                console.log(row)
                return (
                    <div key={rowIndex}>
                        {row.status == MoveStatus.Pending.Name.toLowerCase() && <button className="btn-table-actions" onClick={() => { handleDispenseConfirm(row.id) }}>
                            <Tooltip tooltip={'Confirmar dispensa'} id={"tooltipedit-" + row.Id}>
                                <span aria-hidden="true"><Check /></span>
                            </Tooltip>
                        </button>}
                        {row.status == MoveStatus.Confirmed.Name.toLowerCase() && <button className="btn-table-actions" onClick={() => { handleDispenseDispatch(row.id) }}>
                            <Tooltip tooltip={'Dispensar'} id={"tooltipedit-" + row.Id}>
                                <span aria-hidden="true"><Export /></span>
                            </Tooltip>
                        </button>}
                    </div>
                )
            }
        }
    ]

    return (<>
        <Card
            header={<div>
                <button onClick={() => handleDispenseRequest(0)} className="btn btn-primary">
                    <Tooltip tooltip={'Solicitar nuevo protocolo'}>
                        <span aria-hidden="true"><Add /></span>
                    </Tooltip>
                </button>
            </div >}
            content={< Loader isLoading={isLoading} >
                <Row>
                    <Col sm={12}>
                        <BootstrapTable
                            bordered={false}
                            hover
                            remote
                            condensed
                            keyField='id'
                            classes="table-spaced table-spaced-small"
                            wrapperClasses="table-responsive"
                            columns={columns}
                            data={pagedMoves.Values}
                            loading={isLoading}
                            noDataIndication={() => <>{t("commons.noData")}</>}
                            pagination={paginationFactory({
                                page: pagedMoves.PageNumber,
                                sizePerPage: pagedMoves.PageSize,
                                totalSize: pagedMoves.TotalCount,
                                hideSizePerPage: true
                            })}
                            onTableChange={handleTableChange}
                        />
                    </Col>
                </Row>
            </Loader >}
        />
    </>)
}