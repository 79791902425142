import { EnumValue } from "../../Common/Util";

export class StockById {
    constructor({ itemIds = [] }) {
        this.itemIds = itemIds;
    }
}

class Assignment {
    constructor({ assignId, assignmentType }) {
        this.assignId = assignId;
        this.assignmentType = assignmentType;
    }
}

export const Assigments = {
    General: 'General',
    Protocol: 'Protocol'
}

export const GeneralAssigment = new Assignment({ assignId: null, assignmentType: 'General' })
export const ProtocolAssigment = (protocolId) => {
    if (protocolId == undefined || protocolId == null || protocolId < 1)
        throw new Error('Invalid protocol ID');

    return new Assignment({ assignId: protocolId, assignmentType: 'Protocol' })
}

export class Release {
    constructor({ id, lotNumber, expirationDate, quantity }) {
        this.id = id;
        this.lotNumber = lotNumber;
        this.expirationDate = expirationDate;
        this.quantity = quantity;
    }
}

export class InventoryItem {
    constructor({ id, presentation, externalId, releases = [] }) {
        this.id = id;
        this.releases = releases;
        this.presentation = presentation;
        this.externalId = externalId;
    }
    #existingReleases = new Map()
    #newReleases = new Map()

    AddExistingRelease(releaseId, quantity) {
        if (this.#existingReleases.has(releaseId)) {
            const currentValue = this.#existingReleases.get(releaseId);
            this.#existingReleases.set(releaseId, currentValue + quantity);
        } else {
            this.#existingReleases.set(releaseId, quantity)
        }
    }

    AddNewRelease({ lotNumber, expirationDate, quantity }) {
        if (this.#newReleases.has(lotNumber)) {
            const currentRelease = this.#existingReleases.get(lotNumber);
            currentRelease.quantity += quantity;
            this.#newReleases.set(lotNumber, currentRelease);
        } else {
            this.#newReleases.set(lotNumber, new Release({ expirationDate, lotNumber, quantity }))
        }
    }

    GetReleases() {
        return new Array()
            .concat(this.#existingReleases.entries().map(([key, value]) => new Release({ id: key, quantity: value })))
            .concat(this.#newReleases.values)
    }
}

export class Stock {
    constructor({ items = [], assignment }) {
        this.items = items;
        this.assignment = assignment;
    }
}

export class ItemStock {
    constructor({ itemId, stock }) {
        this.itemId = itemId;
        this.stock = stock
    }
}

export class ReleaseStockResult {
    constructor({ itemId, releaseId, lotNumber, expirationDate, quantity }) {
        this.itemId = itemId;
        this.releaseId = releaseId;
        this.lotNumber = lotNumber;
        this.expirationDate = expirationDate;
        this.quantity = quantity;
    }
}

export const ReleaseStockState = {
    Deleted: new EnumValue(-1, "Deleted"),
    Available: new EnumValue(1, "Available"),
    Dispensed: new EnumValue(2, "Dispensed"),
    WaitingForReturn: new EnumValue(3, "WaitingForReturn"),
    WaitingForDestruction: new EnumValue(4, "WaitingForDestruction"),
    Destroyed: new EnumValue(5, "Destroyed"),
    Missed: new EnumValue(6, "Missed"),
    Damaged: new EnumValue(7, "Damaged"),

    From: (name) => {
        switch (name?.toLowerCase()) {
            case "available": return ReleaseStockState.Available;
            case "dispensed": return ReleaseStockState.Dispensed;
            case "waitingforReturn": return ReleaseStockState.WaitingForReturn;
            case "waitingforDestruction": return ReleaseStockState.WaitingForDestruction;
            case "destroyed": return ReleaseStockState.Destroyed;
            case "missed": return ReleaseStockState.Missed;
            case "damaged": return ReleaseStockState.Damaged;
            default: return EnumValue.Unknown();
        }
    },

    ToArray: () => [
        ReleaseStockState.Available,
        ReleaseStockState.Dispensed,
        ReleaseStockState.WaitingForReturn,
        ReleaseStockState.WaitingForDestruction,
        ReleaseStockState.Destroyed,
        ReleaseStockState.Missed,
        ReleaseStockState.Damaged
    ]
};


export const AssignmentType = {
    General: new EnumValue(1, "General"),
    Protocol: new EnumValue(2, "Protocol"),
    From: (name) => {
        switch (name?.toLowerCase()) {
            case "general": return AssignmentType.General;
            case "protocol": return AssignmentType.Protocol;
            default: return EnumValue.Unknown();
        }
    }, ToArray: () => [
        AssignmentType.General,
        AssignmentType.Protocol,
    ]
}
