import { useState } from "react";
import { PaginationRequest, PaginationResult } from "../../../api/Common/Util";
import InventoryStockService from "../../../api/Inventory/InventoryStockService";
import useLoading from "../../../components/Common/useLoading";
import { getProtocol } from "../../../api/protocol";

function propsTemplate() { return { ...new PaginationRequest(), releaseId: null } }
export default function useReleasesStock(props = propsTemplate()) {
    const [pagedStock, setPagedStock] = useState(new PaginationResult())
    const [isLoading, load, reload] = useLoading({
        onLoad: async () => {
            await Find(props)
        },
    })

    const Navigation = {
        ToPage: async ({ page }) => load(async () => {
            const request = pagedStock.ToPage(page);

            if (request == null) return

            await Find(request)
        }),
        Search: async (props = propsTemplate()) => load(async () => {
            await Find(props)
        }),
        Reload: reload
    }

    async function Find(props) {
        const result = await InventoryStockService.Search({ ...props })

        result.SortColumn = props.sortColumn
        result.SortOrder = props.sortOrder

        const protocolIds = result.Values.reduce((set, stock) => {
            if (validateAssignType(stock.assignment, "protocol"))
                set.add(stock.assignment.assignId)
            return set
        }, new Set());

        const protocolsMap = new Map();
        for (const id of protocolIds) {
            if (!protocolsMap.has(id)) {
                const protocol = await getProtocol(id);
                protocolsMap.set(id, protocol.data.title);
            }
        }
        
        result.Values.forEach(stock => {
            stock.assignName = validateAssignType(stock.assignment, "protocol")
                ? protocolsMap.get(stock.assignment.assignId)
                : null
        })

        setPagedStock(result)
    }

    function validateAssignType(assignment, value) {
        if (assignment == null || assignment == undefined) return false

        if (assignment.assignmentType?.toLowerCase() == value) return true
    }

    return [pagedStock, isLoading, Navigation]
}