import React from "react";
import { useReducer } from "react"

function ModalStateReducer(state, action) {
    switch (action.type?.toLowerCase()) {
        case 'open': return { value: action.value, show: true }
        case 'close':
        default: return { value: undefined, show: false }
    }
}

export function useModalContext({ handleEvent } = {}) {
    const [state, dispatch] = useReducer(ModalStateReducer, {
        show: false,
        value: undefined
    })

    const onClose = () => dispatch({ type: 'close' })
    const onOpen = (value) => dispatch({ type: 'open', value: value })
    const onEvent = (e) => { if (handleEvent !== null) handleEvent(e) }

    return [{ ...state, onClose, onEvent }, onOpen]
}

export const useModalHook = (ModalComponent) => {
    const [state, dispatch] = useReducer(ModalStateReducer, {
        show: false,
        value: null
    })

    const onClose = () => dispatch({ type: 'close' })
    const onOpen = (value = null) => dispatch({ type: 'open', value: value })

    const WrappedModal = () => state.show
        ? (<ModalComponent {...state} onClose={onClose}> </ModalComponent >)
        : null

    return [onOpen, WrappedModal]
}