import React, { useEffect, useState, useRef, forwardRef, useImperativeHandle } from 'react';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
export default forwardRef(({
    id = null,
    fetchOptions = (term) => { },
    labelKey = (option) => { },
    onNext = () => { },
    onBack = () => { },
    disabled = false,
    placeholder = "",
    minLength = 3,
    onChange = (selected) => { }
}, ref) => {
    const [options, setOptions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const reference = useRef();

    const onSearch = async (term) => {
        setIsLoading(true);

        try {
            const result = await fetchOptions(term)
            setOptions(result)
        } catch (e) {
            setOptions([])
        }

        setIsLoading(false);
    }
    const onKeyDown = (e) => {
        if (e.key == 'Escape' || e.keyCode == 27) {
            reference.current.getInstance().clear()
            if (onChange != null) onChange(null)
            if (onBack != null) onBack()
        }
        if ((e.key == 'Enter' || e.keyCode == 13) && onNext != null) {
            onNext()
        }
    }

    useImperativeHandle(ref, () => ({
        clear: () => {
            reference.current.getInstance().clear()
        },
        focus: () => {
            reference.current.getInstance().focus()
        }
    }));

    return (<AsyncTypeahead
        id={id}
        ref={reference}
        labelKey={labelKey}
        isLoading={isLoading}
        options={options}
        clearButton={true}
        filterBy={() => true}
        disabled={disabled}
        onSearch={onSearch}
        minLength={minLength}
        placeholder={placeholder}
        highlightOnlyResult={true}
        delay={500}
        onChange={onChange}
        onKeyDown={onKeyDown}
        onBlur={() => { }}
    />)
})