import { useEffect, useState } from "react";
import { PaginationRequest, PaginationResult } from "../../../api/Common/Util";
import useLoading, { useLoad } from "../../../components/Common/useLoading";
import InventoryMovementService from "../../../api/Inventory/InventoryMovementService";
import { getUsersById } from "../../../api/identity";
import { useAuthorization, useProtocolAuthFilter } from "../../../components/Authorization/useAuthorization";
import { getProtocol } from "../../../api/protocol";

const propsTemplate = () => {
    return {
        ...new PaginationRequest()
        , includedTypes: null
        , status: null
        , assignmentTypes: null
        , assignId: null
        , useId: null
    }
}
export default function useMoves(props = propsTemplate()) {
    const [request, setRequest] = useState(props)
    const [pagedMoves, setPagedMoves] = useState(new PaginationResult())
    const [isLoading, withLoad] = useLoad()

    const [isAuthenticated, user, isAuthorized] = useAuthorization()
    const [canViewProtocol, isAuthLoading] = useProtocolAuthFilter()

    useEffect(() => {
        if (request == null) return
        withLoad(() => findMoves(request))
    }, [isAuthenticated])


    const Navigation = {
        ToPage: async ({ page }) => {
            const newRequest = pagedMoves.ToPage(page);
            if (newRequest == null) return
            setRequest({ ...request, ...newRequest })
            await withLoad(() => findMoves({ ...request, ...newRequest }))
        },
        Search: async (props = propsTemplate()) => {
            await withLoad(() => findMoves({ ...request, ...props }))
        },
    }

    async function findMoves(props) {
        let assigmentGeneralConcat = appendAssignment("", isAuthorized, "GeneralInventory.View", "general")
        let assigmentProtocolConcat = appendAssignment(assigmentGeneralConcat, isAuthorized, "ProtocolInventory.View", "protocol")

        const assignmentTypes =
            request.assignmentTypes == null
                ? assigmentProtocolConcat
                : request.assignmentTypes

        const result = await InventoryMovementService.GetMoves({ ...props, assignmentTypes: assignmentTypes })

        result.SortColumn = request.sortColumn
        result.SortOrder = request.sortOrder

        const userIds = result.Values.reduce((set, move) => {
            set.add(move.userId)
            return set
        }, new Set());
        const usersResponse = (await getUsersById(Array.from(userIds))).data;
        const usersMap = new Map(usersResponse.map(user => [user.userId, user]));
        result.Values.forEach(move => {
            move.user = usersMap.get(move.userId)
        })
        result.Values = result
            .Values
            .filter(m => validateAssignType(m.assignment, "general") || canViewProtocolMove(m, canViewProtocol))

        const protocolIds = result.Values.reduce((set, move) => {
            if (validateAssignType(move.assignment, "protocol"))
                set.add(move.assignment.assignId)
            return set
        }, new Set());

        const protocolsMap = new Map()
        for (const id of protocolIds) {
            if (!protocolsMap.has(id)) {
                const protocol = await getProtocol(id);
                protocolsMap.set(id, protocol.data.title);
            }
        }

        result.Values.forEach(move => {
            move.assignName = validateAssignType(move.assignment, "protocol")
                ? protocolsMap.get(move.assignment.assignId)
                : null
        })

        setPagedMoves(result)
    }

    function canViewProtocolMove(move, canViewProtocolCallback) {
        return validateAssignType(move.assignment, "protocol") && canViewProtocolCallback(move.assignment.assignId)
    }

    function appendAssignment(root, isAuthorizedCallback, permission, assigment) {
        if (isAuthorizedCallback([permission])) {
            return root + (root == ""
                ? assigment
                : `,${assigment}`)
        }
        return root
    }

    function validateAssignType(assignment, value) {
        if (assignment == null || assignment == undefined) return false

        if (assignment.assignmentType?.toLowerCase() == value) return true
    }

    return [pagedMoves, isLoading, Navigation]
}