import axios from '../index';
import { PaginationRequest, PaginationResult, ResponseResult, Url } from "../Common/Util";
import { InventoryItem } from "./Entities/InventoryEntities";

const GetUrl = Url("api/v1/Inventory/InventoryItems");

export default {
    Find,
    GetById
}

async function Find({ search, pageNumber, sortColumn, sortOrder, pageSize = 10, protocolId = null } = {}) {
    const response = await axios.get(GetUrl(), {
        params: {
            search, pageNumber, sortColumn, sortOrder, pageSize, protocolId
        }
    });
    return new PaginationResult(response.data);
}

async function GetById(itemId) {
    if (isNaN(itemId) || itemId < 1) throw new Error('The ID is invalid')
    const response = await axios.get(GetUrl(`/${itemId}`))
    return new ResponseResult(response.data);
}