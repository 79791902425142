import { useTranslation } from "react-i18next";
import AutoCompletation from "../../../components/Common/AutoCompletation";
import React, { useRef, useState } from "react"
import moment from "moment";
import { Grid, Row, Col, FormGroup, ControlLabel, FormControl } from 'react-bootstrap'
import InventoryMedicationService from "../../../api/Inventory/InventoryMedicationService";
import InventoryReleaseService from "../../../api/Inventory/InventoryReleaseService";
import InventoryStockService from "../../../api/Inventory/InventoryStockService";
import { ReleaseStockState } from "../../../api/Inventory/Entities/InventoryEntities";
import { CustomDate } from "../../../components/CustomControls/CustomDateTime";

export default function DispenseForm({ assignmentType, assignId, handleSubmit }) {
    const { t } = useTranslation();
    const expirationDateRef = useRef();
    const expirationDateInputRef = useRef();
    const quantityRef = useRef();
    const medicationRef = useRef();
    const lotRef = useRef();
    const kitNumberRef = useRef();
    const [selectedItem, setSelectedItem] = useState(null);
    const [selectedRelease, setSelectedRelease] = useState(null);
    const [selectedKit, setSelectedKit] = useState(null);
    const [quantity, setQuantity] = useState(1);

    const fetchItems = async (term) => {
        const result = await InventoryMedicationService.Find({ term: term, onlyLocal: true, pageSize: 100 })
        return result.Value
    }
    const fetchReleases = async (term) => {
        if (selectedItem == null || selectedItem.inventoryItemId == null) return []

        const result = await InventoryReleaseService.Find({ page: 1, pageSize: 100, itemId: selectedItem.inventoryItemId, search: term, includeStock: true })
        return result.Values
    }
    const fetchStock = async (term) => {
        if (selectedRelease == null) return []

        const result = await InventoryStockService.Search({
            page: 1, pageSize: 100,
            includedStates: [ReleaseStockState.Available],
            releaseId: selectedRelease.id, assignId: assignId, assignmentType: assignmentType
        })

        const filteredResult = result
            .Values
            .filter(s => s.assignment.kitNumber.includes(term))
            .reduce((acc, stock) => {
                if (acc.has(stock.assignment.kitNumber))
                    return acc

                acc.set(stock.assignment.kitNumber, stock)
                return acc
            }, new Map())
        return Array.from(filteredResult.values());
    }
    const curringSelected = (callback) => {
        return (selected) => {
            if (selected != null && selected.length > 0) callback(selected[0])
            else callback(null)
        }
    }

    function handleQuantityChange(e) {
        const value = e.target.value;
        if (isNaN(value) || value < 1) return;

        setQuantity(value)
    }

    function isFormValid() {
        return selectedItem !== null
            && selectedRelease !== null
            && selectedKit !== null
            && quantity > 0
    }
    function handleItemClean() {
        setSelectedItem(null)
        setSelectedRelease(null)
        setSelectedKit(null)
        setQuantity(1)
        medicationRef.current.clear()
        lotRef.current.clear()
        kitNumberRef.current.clear()
    }

    function handleFormSubmit(e) {
        e.preventDefault();

        if (!isFormValid() && handleSubmit !== null) return
        handleSubmit({
            item: selectedItem,
            release: selectedRelease,
            assignment: selectedKit.assignment,
            quantity: quantity
        })
        handleItemClean()
        medicationRef.current.focus()
    }

    return (<>
        <form onSubmit={handleFormSubmit} autoComplete="off">
            <div className='input-group-container'>
                <FormGroup style={{ width: "100%" }}>
                    <AutoCompletation
                        id={'medication'}
                        ref={medicationRef}
                        labelKey={(option) => {
                            return `${option.presentation} (${option.drug})`;
                        }}
                        placeholder={'Buscar medicamentos'}
                        fetchOptions={fetchItems}
                        onChange={curringSelected(setSelectedItem)}
                        onNext={() => lotRef.current.focus()}
                    />
                </FormGroup>
            </div>
            <hr />
            <div className='input-group-container'>
                <FormGroup style={{ width: "100%" }}>
                    <AutoCompletation
                        id={'Lote'}
                        ref={lotRef}
                        labelKey={(option) => {
                            return `L: ${option.lotNumber} - V: ${moment(option.expirationDate).format('DD-MM-YYYY')} - stock: ${option.stockQuantity}`;
                        }}
                        disabled={selectedItem == null}
                        fetchOptions={fetchReleases}
                        placeholder={'Numero de Lote'}
                        onChange={curringSelected(setSelectedRelease)}
                        onBack={() => medicationRef.current.focus()}
                        onNext={() => kitNumberRef.current.focus()}
                    />
                </FormGroup>

                <FormGroup style={{ width: "100%" }} >
                    <CustomDate
                        dateRef={expirationDateRef}
                        inputRef={expirationDateInputRef}
                        name={`expirationDate`}
                        onChange={() => { }}
                        onBlur={() => { }}
                        value={selectedRelease?.expirationDate ?? null}
                        defaulValue={null}
                        dateFormat="DD/MM/YYYY"
                        disabled={true}
                        inputProps={{ placeholder: t('commons.expirationDate') }}
                    />
                </FormGroup>
            </div>
            <hr />
            <div style={{ display: "flex", flexDirection: 'column', justifyContent: 'end' }} >
                <div className='input-group-container'>
                    <FormGroup style={{ marginBottom: '0px', width: "100%" }} >
                        <AutoCompletation
                            id={'Lote'}
                            ref={kitNumberRef}
                            labelKey={(option) => {
                                return `${option.assignment.kitNumber}`;
                            }}
                            minLength={0}
                            disabled={selectedRelease == null}
                            fetchOptions={fetchStock}
                            placeholder={'Numero de KIT'}
                            onChange={curringSelected(setSelectedKit)}
                            onBack={() => lotRef.current.focus()}
                            onNext={() => quantityRef.current.focus()}
                        />
                    </FormGroup>
                    <FormGroup style={{ marginBottom: '0px', width: "100%" }}>
                        <input ref={quantityRef} className='form-control' placeholder="Cantidad" type="number" min="1" name={'item.Quantity'}
                            disabled={selectedKit == null}
                            value={quantity} onChange={handleQuantityChange} autoComplete="off" />
                    </FormGroup>
                </div>
                <div style={{ alignSelf: 'self-end', marginTop: '1rem' }}>
                    <button type="submit" className='btn btn-success' disabled={false}>{t('buttons.add')}</button>
                </div>
            </div>
        </form>
    </>)
}