import axios from '../index';
import { PaginationRequest, PaginationResult, ResponseResult, Url } from "../Common/Util";
import { ItemStock, ReleaseStockResult } from "./Entities/InventoryEntities";

const GetUrl = Url("api/v1/Inventory/Stock");

export default {
    Find,
    FindByRelease,
    Search
}

async function Search({ pageNumber, sortColumn, sortOrder, pageSize = 10, includedStates = [], assignId = null, assignmentType = null, releaseId = null } = {}) {

    const includedStatesFormmatted = includedStates != null ? includedStates.map(s => s.Name).join() : null;

    const response = await axios.get(GetUrl(""), {
        params: {
            pageNumber,
            sortColumn,
            sortOrder,
            pageSize,
            includedStates: includedStatesFormmatted,
            assignId,
            assignmentType: assignmentType?.Name ?? null,
            releaseId
        }
    });
    return new PaginationResult(response.data);
}

async function Find({ itemIds }) {
    const response = await axios.post(GetUrl('/find'), { itemIds })
    const items = response.data.value.map(item => new ItemStock(item))
    return new ResponseResult({ ...response.data, value: items })
}

async function FindByRelease({ itemIds }) {
    const response = await axios.post(GetUrl('/findByRelease'), { itemIds })
    return new ResponseResult({ ...response.data })
}