import { useState } from "react";
import { PaginationRequest, PaginationResult } from "../../../api/Common/Util";
import InventoryReleaseService from "../../../api/Inventory/InventoryReleaseService";
import useLoading from "../../../components/Common/useLoading";

function propsTemplate() { return { ...new PaginationRequest(), includeStock: false, itemId: 0 } }

export default function useReleases({ preload = false, requestProps = propsTemplate(), } = {}) {
    const [pagedReleases, setPagedReleases] = useState(new PaginationResult())
    const [isLoading, load, reload] = useLoading({
        onLoad: async () => {
            if (!preload) return;

            const result = await InventoryReleaseService.Find(requestProps)
            setPagedReleases(result)
        },
    })

    const Navigation = {
        Search: async (props = propsTemplate()) => load(async () => {
            await Find(props)
        }),
        ToPage: async ({ page }) => load(async () => {
            const request = pagedReleases.ToPage(page);

            if (request == null) return

            await Find(request)
        }),
        Reload: reload
    }

    async function Find(props) {
        const result = await InventoryReleaseService.Find(props)

        result.SortColumn = props.sortColumn
        result.SortOrder = props.sortOrder

        setPagedReleases(result)
    }

    return [pagedReleases, isLoading, Navigation]
}