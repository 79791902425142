import { EnumValue, UniqueId } from "../../Common/Util";

export class MovementResponse {
    constructor({ id, moveType, createAt, status, items, assignment, user, userId } = {}) {
        this.id = id;
        this.moveType = moveType?.toLowerCase();
        this.createAt = createAt;
        this.status = status?.toLowerCase();
        this.items = items ?? [];
        this.assignment = assignment;
        this.assignmentType = assignment?.assignmentType?.toLowerCase();
        this.user = user;
        this.userId = userId;
    }
    type() {
        return this.items[0]?.assignment ?? "";
    }
}

export class MovementItemResponse {
    constructor({ id, itemId, itemName, releaseId, lotNumber, quantity, confirmedQuantity, presentation, assignment }) {
        this.id = id;
        this.itemId = itemId;
        this.itemName = itemName
        this.releaseId = releaseId;
        this.lotNumber = lotNumber;
        this.quantity = quantity;
        this.confirmedQuantity = confirmedQuantity;
        this.presentation = presentation;
        this.assignment = assignment;
    }

}

export class MoveRequest {
    constructor({ id, moveType, userId, items = [], assignment, confirmationUserId } = {}) {
        this.id = id ?? 0;
        this.moveType = moveType;
        this.userId = userId;
        this.confirmationUserId = confirmationUserId;
        this.items = items ?? [];
        this.assignment = assignment;
    }
}

export class MoveItemRequest {
    constructor({ id, item, presentation, release, quantity, confirmedQuantity, assignment }) {
        this.id = id;
        this.item = item
        this.release = release;
        this.presentation = presentation;
        this.quantity = quantity;
        this.confirmedQuantity = confirmedQuantity;
        this.assignment = assignment;
        this.uniqueId = UniqueId();
    }

    confirmQuantity(confirmed) {
        if (confirmed < 0 || confirmed > this.quantity)
            throw new Error("Cantidad de confirmación no válida")
        this.confirmedQuantity = confirmed;
    }
}

export class MoveItemAssigment {
    constructor({ name, id = null }) {
        this.name = name;
        this.id = id;
    }
}

export const MoveTypes = {
    AddStock: new EnumValue(1, "add_stock"),
    AdjustStock: new EnumValue(2, "adjust_stock"),
    ProtocolDispense: new EnumValue(3, "protocol_dispense"),
    TryParse: (type) => {
        switch (type?.toLowerCase()) {
            default: return EnumValue.Unknown()
            case "add_stock": return MoveTypes.AddStock
            case "adjust_stock": return MoveTypes.AdjustStock
            case "protocol_dispense": return MoveTypes.ProtocolDispense
        }
    },
    ToArray: () => [MoveTypes.AddStock, MoveTypes.AdjustStock, MoveTypes.ProtocolDispense],
    IsEquivalent: (value, expected) => value?.toLowerCase() == expected?.Name.toLowerCase()
}


export const MoveStatus = {
    Pending: new EnumValue(1, "Pending"),
    Confirmed: new EnumValue(2, "Confirmed"),
    Completed: new EnumValue(3, "Completed"),
    Canceled: new EnumValue(4, "Canceled"),
    TryParse: (state) => {
        switch (state?.toLowerCase()) {
            default: return EnumValue.Unknown();
            case "pending": return MoveStatus.Pending;
            case "confirmed": return MoveStatus.Confirmed;
            case "completed": return MoveStatus.Completed;
            case "canceled": return MoveStatus.Canceled;
        }
    },
    ToArray: () => [MoveStatus.Pending, MoveStatus.Confirmed, MoveStatus.Completed, MoveStatus.Canceled]
};


