import axios from '../index';
import { PaginationRequest, PaginationResult, ResponseResult, Url } from "../Common/Util";

const GetUrl = Url("api/v1/Inventory/Release");

export default {
    Find,
    GetById
}


async function Find({
    search,
    itemId,
    onlyAvailable,
    includeStock,
    pageNumber,
    sortColumn,
    sortOrder,
    pageSize } = {}) {

    if (isNaN(itemId) && itemId < 1) throw new Error('Invalid item id');

    const response = await axios.get(GetUrl(''), {
        params: { ...new PaginationRequest({ pageNumber, pageSize, search, sortColumn, sortOrder }), includeStock, itemId, onlyAvailable }
    });
    const data = new PaginationResult(response.data);
    return data;
}

async function GetById(releaseId) {
    if (isNaN(releaseId) && releaseId < 1) throw new Error('Invalid release id');
    const response = await axios.get(GetUrl(`/${releaseId}`));
    return new ResponseResult({ ...response.data });
}