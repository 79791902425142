import React from "react"
import './Field.css'

export const Field = ({ title, value, spaceType }) => {

    const space = spaceType === 'between' ? 'justify-content-between' : 'justify-content-around'

    return (<div className={`info-container ${space}`}>
        <div>
            <span className="info-label">{title}: </span>
        </div>
        <div>
            <span className="info-text">{value}</span>
        </div>
    </div>)
}