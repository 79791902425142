import { useState } from "react";
import InventoryMovementService from "../../../api/Inventory/InventoryMovementService";
import { MovementResponse } from "../../../api/Inventory/Entities/InventoryMovementEntities";
import useLoading from "../../../components/Common/useLoading";
import { getUsersById } from "../../../api/identity";
import { getProtocol } from "../../../api/protocol";



export default function useMove(moveId) {
    const [move, setMove] = useState(new MovementResponse())
    const [isLoading, reload] = useLoading({
        onLoad: async () => {
            if (moveId == undefined || moveId == 0) return;
            const result = await InventoryMovementService.GetById(moveId)

            if (result.Succeeded) {
                const move = result.Value
                const usersResponse = (await getUsersById([move.userId])).data;
                const usersMap = new Map(usersResponse.map(user => [user.userId, user]));
                move.user = usersMap.get(move.userId)

                move.assignName = "";
                if (move.assignment.assignmentType == "Protocol") {
                    const protocolResponse = (await getProtocol(move.assignment.assignId)).data
                    move.assignName = protocolResponse.title
                }

                setMove(move)
            }
        },
        onError: () => {
            setMove(new MovementResponse())
        }
    })

    return [move, isLoading, reload]
}