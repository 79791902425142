import { useState } from "react";
import useLoading from "../../../components/Common/useLoading";
import InventoryReleaseService from "../../../api/Inventory/InventoryReleaseService";

export default function useRelease(id) {
    const [release, setRelease] = useState(null);
    const [isLoading, withLoad, reload] = useLoading({
        onLoad: async () => {
            if (!(id >= 1)) return
            
            const response = await InventoryReleaseService.GetById(id);
            setRelease(response.Value);
        }
    });

    return [release, isLoading];
} 