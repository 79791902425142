import React, { useRef, useState } from "react"
import { useTranslation } from "react-i18next";
import '../../../assets/css/common.css';

import { AssignmentType } from "../../../api/Inventory/Entities/InventoryEntities";
import DispenseForm from "../../Inventory/Shared/DispenseForm";
import useInventoryCart from "../../Inventory/Hooks/useInventoryCart";
import { MoveTypes } from "../../../api/Inventory/Entities/InventoryMovementEntities";
import Loader from "../../../components/Common/Loader";
import { InventoryItemCart } from "../../Inventory/Shared/InventoryItemCart";
import useConfirmationModal from "../../../components/Modals/ConfirmationModal/useConfirmationModal";
import { ErrorMessage, Success } from "../../../components/Notifications/Notifications";
import InventoryOperationService from "../../../api/Inventory/InventoryOperationService";
import { useHistory } from "react-router-dom"

export default function ProtocolDispenseRequestForm({ match: { params } }) {
    const { t } = useTranslation();
    const history = useHistory();
    const [move, isMoveLoading, actions] = useInventoryCart(0, MoveTypes.ProtocolDispense.Name, AssignmentType.Protocol.Name, params.protocolId)
    const { protocolId } = params;
    const [Modal, modalActions] = useConfirmationModal();
    const [isWorking, setIsWorking] = useState(false)

    function handleNewItem(item) {
        actions.AddItem(item.item, item.release, item.quantity, item.assignment)
    }
    function onDeleteItem(item, _) {

        actions.RemoveItem(item)
    }
    function handleItemChange(quantity, item) {
        actions.UpdateQuantity(item, quantity)
    }

    function onHandleCancel() {
        history.push(`/admin/protocols/${protocolId}`)
    }

    async function onHandleSubmit() {
        modalActions.Show({
            title: t('inventory.movements.confirmations.completeTitle'),
            textContent: t('inventory.movements.confirmations.completeTextContent'),
            buttonText: t('buttons.confirm'),
            onConfirmation: async () => {
                if (isWorking == true) return;
                try {
                    setIsWorking(true)
                    const response = await InventoryOperationService.SavePending(move)
                    if (response.Succeeded) {
                        history.push(`/admin/protocols/${protocolId}`)
                        Success("commons.successSave")
                    }
                    else
                        ErrorMessage("errors.serverError")
                } catch (e) {
                    ErrorMessage("errors.serverError")

                } finally {
                    setIsWorking(false)
                }
            }
        })
    }

    return (<>
        <Loader isLoading={isMoveLoading}>
            <div className='max-width-1500 margin-center'>
                <div className='container-fluid form-container' style={{ fontWeight: "bolder" }}>
                    <h5><strong>Solicitud de Dispensa</strong></h5>
                </div>
                <div className='container-fluid form-container'>
                    <div className='right-side' style={{ height: 'max-content', maxWidth: 'max-content' }}>
                        <DispenseForm assignmentType={AssignmentType.Protocol} assignId={protocolId} handleSubmit={handleNewItem} />
                    </div>
                    <div className='left-side' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '45vh' }}>
                        <InventoryItemCart items={move.items} onDeleteItem={onDeleteItem} onItemsChange={handleItemChange}
                            extraColumns={[{
                                title: 'KIT', width: '9.375rem', formatter: (index, row) => row.assignment.kitNumber
                            }]} />

                        <div style={{ textAlign: 'right', alignSelf: 'self-end' }}>
                            <button className='btn btn-secondary ml-1' style={{ marginRight: '1rem' }} onClick={onHandleCancel}>{t('buttons.cancel')}</button>
                            <button className='btn btn-success' disabled={!(move.items.length > 0)} onClick={onHandleSubmit}>{t('buttons.finish')}</button>
                        </div>
                    </div>
                </div>
            </div>
        </Loader>
        <Modal />
    </>)
}