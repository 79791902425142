import React, { useEffect, useState } from 'react';
import { InventoryForm } from "./InventoryForm";
import { InventoryItemCartExpandable } from "./InventoryItemCart";
import useInventoryCart from '../Hooks/useInventoryCart';
import Loader from '../../../components/Common/Loader';
import { CustomSelect } from '../../../components/CustomControls/CustomSelect';
import { Col, Row, FormGroup, ControlLabel } from 'react-bootstrap';
import { ProtocolAssigment } from '../../../api/Inventory/Entities/InventoryEntities';
import { useTranslation } from 'react-i18next';
import { useLaboratoryProtocolInventory } from '../Hooks/useLaboratoryProtocolInventory';
import { ErrorMessage, Success } from '../../../components/Notifications/Notifications';
import { useHistory } from "react-router-dom"
import InventoryOperationService from '../../../api/Inventory/InventoryOperationService';
import { EnumValue } from '../../../api/Freezer/Entities/Utils';
import useConfirmationModal from '../../../components/Modals/ConfirmationModal/useConfirmationModal';
import InventoryMedicationService from '../../../api/Inventory/InventoryMedicationService';

export function InventoryProtocolMoveForm({ title, moveId, moveType, assignmentType, onlyLocalInventory = false }) {
    const { t } = useTranslation()
    const [move, isLoading, actions] = useInventoryCart(moveId, moveType, assignmentType, {
        maxQuantityPerRow: 1
    })
    const [protocolState, handleLaboratoryChange, getProtocolById] = useLaboratoryProtocolInventory();
    const [selectedLaboratory, setSelectedLaboratory] = useState(null)
    const [selectedProtocol, setSelectedProtocol] = useState(null)
    const history = useHistory();
    const [Modal, modalActions] = useConfirmationModal();
    const [isWorking, setIsWorking] = useState(false)

    useEffect(() => {
        if (selectedLaboratory === null) return
        handleLaboratoryChange(selectedLaboratory.Value)
    }, [selectedLaboratory])

    useEffect(() => {
        if (selectedProtocol === null) return
        actions.SetAssignId(selectedProtocol.Value)
    }, [selectedProtocol])


    useEffect(() => {
        async function PreselectProtocol(protocolId) {
            const protocol = await getProtocolById(protocolId)

            setSelectedProtocol(new EnumValue(protocol.id, protocol.title))
            setSelectedLaboratory(new EnumValue(protocol.laboratory.id, protocol.laboratory.title))
        }

        if (!isLoading) {
            const protocolId = move.assignment.assignId
            if (isNaN(protocolId) || protocolId < 1) return
            PreselectProtocol(protocolId)
        }
    }, [move?.id])


    if (moveType == undefined)
        throw new Error("MoveType not Assigned")

    function handleNewItem(item) {
        const items = Array.from({ length: item.quantity }, (_, i) => { return { ...item } })
        const cartItems = items.map((item) => ({ item: item.selectedItem, release: item.selectedRelease, quantity: 1, assignment: ProtocolAssigment(selectedProtocol.Value) }))
        actions.AddItems(cartItems)
    }

    function onDeleteItem(item, _) {
        actions.RemoveItem(item)
    }

    function handleItemChange(quantity, item) {
        actions.UpdateQuantity(item, quantity)
    }

    async function onHandleSubmit() {
        modalActions.Show({
            title: t('inventory.movements.confirmations.completeTitle'),
            textContent: t('inventory.movements.confirmations.completeTextContent'),
            buttonText: t('buttons.confirm'),
            onConfirmation: async () => {
                if (isWorking == true) return;
                try {
                    setIsWorking(true)
                    const response = await InventoryOperationService.ExcecuteOperation(move)
                    if (response.Succeeded) {
                        history.push("/admin/inventory")
                        Success("commons.successSave")
                    }
                    else
                        ErrorMessage("errors.serverError")
                } catch (e) {
                    ErrorMessage("errors.serverError")
                }finally{
                    setIsWorking(false)
                }
            }
        })
    }
    
    async function onHandlePending() {
        modalActions.Show({
            title: t('inventory.movements.confirmations.pendingTitle'),
            textContent: t('inventory.movements.confirmations.pendingTextContent'),
            buttonText: t('buttons.confirm'),
            onConfirmation: async () => {
                if (isWorking == true) return;
                try {
                    setIsWorking(true)
                    const response = await InventoryOperationService.SavePending(move)

                    if (response.Succeeded) {
                        Success("commons.successSave")
                        history.push("/admin/inventory")
                    }
                    else
                        ErrorMessage("errors.serverError")
                } catch (e) {
                    ErrorMessage("errors.serverError")
                } finally {
                    setIsWorking(false)
                }
            }
        })
    }

    function onHandleCancel() {
        history.push("/admin/inventory")
    }

    async function onHandleSearchItem(value) {
        const result = await InventoryMedicationService.Find({ term: value, onlyLocal: onlyLocalInventory, protocolId: selectedProtocol.Value, pageSize: 100 })
        return result.Value
    }

    const handleUpdateKit = (row, value) => {
        actions.UpdateItem({ ...row, assignment: { ...ProtocolAssigment(selectedProtocol.Value), kitNumber: value } })
    }
    const extraColumns = [{
        title: 'KIT', width: '9.375rem', formatter: (row, index) => {
            return (<div>
                <input className='form-control' type='text' placeholder='kit number' value={row.assignment?.kitNumber ?? ""} onChange={(args) => handleUpdateKit(row, args.target.value)} />
            </div>)
        }
    }]

    return (<>
        <Loader isLoading={(isLoading && move != undefined) || (move?.id > 0 && selectedProtocol == null)}>
            <div className='max-width-1500 margin-center'>
                <div className='container-fluid form-container' style={{ fontWeight: "bolder" }}>
                    <h5><strong>{title}</strong></h5>

                </div>
                <div className='container-fluid form-container'>
                    <div className='right-side' style={{ height: 'max-content', maxWidth: 'max-content' }}>
                        <div style={{ marginBottom: '2rem' }}>
                            <Row>
                                <Col sm={6}>
                                    <FormGroup>
                                        <ControlLabel>{t('commons.laboratories')}</ControlLabel>
                                        <CustomSelect
                                            name={`select_laboratory`}
                                            value={selectedLaboratory}
                                            onChange={(e, v) => { setSelectedLaboratory(v) }}
                                            options={protocolState.laboratories}
                                            getOptionLabel={(option) => option?.Name}
                                            getOptionValue={(option) => option?.Value}
                                            isDisabled={selectedProtocol != null}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm={6}>
                                    <FormGroup>
                                        <ControlLabel>{t('commons.protocols')}</ControlLabel>
                                        <CustomSelect
                                            name={`protocol_select`}
                                            value={selectedProtocol}
                                            onChange={(e, v) => {
                                                setSelectedProtocol(v)
                                            }}
                                            options={protocolState.protocols}
                                            getOptionLabel={(option) => option?.Name}
                                            getOptionValue={(option) => option?.Value}
                                            isDisabled={(selectedLaboratory === null || !(protocolState.protocols.length > 0)) || move.items.length > 0}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </div>
                        <InventoryForm handleSubmit={handleNewItem} handleSearchItem={onHandleSearchItem} disabled={selectedLaboratory === null || selectedProtocol == null} />
                    </div>
                    <div className='left-side' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '45vh' }}>
                        <div style={{ height: '100%', overflowY: 'auto' }}>
                            <InventoryItemCartExpandable items={move.items} onDeleteItem={onDeleteItem} onItemsChange={handleItemChange} splitQuantity={true} extraColumns={extraColumns} />
                        </div>
                        <div style={{ textAlign: 'right', alignSelf: 'self-end', paddingTop: '.5rem' }}>
                            <button className='btn btn-secondary ml-1' style={{ marginRight: '1rem' }} onClick={onHandleCancel}>{t('buttons.cancel')}</button>
                            <button className='btn btn-warning   ml-1' style={{ marginRight: '1rem' }} disabled={!(move.items.length > 0)} onClick={onHandlePending}>{t('buttons.pending')}</button>
                            <button className='btn btn-success' disabled={!(move.items.length > 0)} onClick={onHandleSubmit}>{t('buttons.finish')}</button>
                        </div>
                    </div>
                </div>
            </div>
        </Loader>
        <Modal />
    </>)
}