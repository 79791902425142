import { useEffect, useState } from "react";
import { EnumValue } from "../../../api/Common/Util";
import { getMasterData, getProtocol, getProtocols } from "../../../api/protocol";
import { useProtocolAuthFilter } from "../../../components/Authorization/useAuthorization";

function ProtocolState({ isFirstLoading, isLoading, laboratories, protocols } = {}) {
    this.isFirstloading = isFirstLoading ?? false;
    this.isLoading = isLoading ?? false;
    this.laboratories = laboratories ?? [];
    this.protocols = protocols ?? [];

    this.StartLoading = function () {
        return new ProtocolState({ ...this, isLoading: true, isFirstLoading: false })
    }
    this.EndLoading = function ({ protocols } = {}) {
        return new ProtocolState({ ...this, isLoading: false, protocols, isFirstLoading: false })
    }
}

export function useLaboratoryProtocolInventory() {
    const [state, setState] = useState(new ProtocolState());
    const [canViewProtocol, isAuthLoading] = useProtocolAuthFilter()

    useEffect(() => {
        async function LoadLaboratories() {
            const masterData = await getMasterData(["Laboratories"]);
            setState(new ProtocolState({
                isFirstLoading: false,
                laboratories: masterData.data?.Laboratories.map(l => new EnumValue(l.id, l.title)) ?? [],
            }))
        }
        LoadLaboratories();
    }, [isAuthLoading])

    const HandleSelectedLaboratoryAsync = async (laboratoryId) => {
        setState(state.StartLoading())

        const protocols = (await getProtocols({
            pagination: { page: 1, sizePerPage: 1000, totalSize: 0 },
            sort: { sortField: "Created", sortOrder: "desc" },
            filters: { laboratoryId: laboratoryId }
        })).data.data

        var filteredProtocols = protocols
            ?.filter(p => canViewProtocol(p.id))
            ?.map(p => new EnumValue(p.id, p.title)) ?? []

        setState(state.EndLoading({ protocols: filteredProtocols }))
    }

    const GetProtocolById = async (protocolId) => {
        const protocol = (await getProtocol(protocolId)).data
        return protocol
    }

    return [state, HandleSelectedLaboratoryAsync, GetProtocolById]
}