import axios from '../index';

import { PaginationRequest, PaginationResult, ResponseResult, Url } from "../Common/Util";
import { MovementItemResponse, MovementResponse } from "./Entities/InventoryMovementEntities";

const GetUrl = Url("api/v1/Inventory/Movement");

export default {
    GetById,
    GetMoves,
}

async function GetById(moveId) {
    const response = await axios.get(GetUrl(`/${moveId}`))
    const move = new MovementResponse(response.data.value)
    move.items = move.items.map(i => new MovementItemResponse(i))
    return new ResponseResult({ ...response.data, value: move })
}

async function GetMoves({ pageNumber, pageSize, sortColumn, sortOrder, includedTypes = null, status = null, assignmentTypes = null, userId = null } = {}) {
    const response = await axios.get(GetUrl(''), {
        params: { ...new PaginationRequest({ pageNumber, pageSize, sortColumn, sortOrder }), includedTypes, status, assignmentTypes, userId }
    })
    const data = new PaginationResult(response.data)
    data.Values = data.Values.map(m => new MovementResponse(m))
    return data;
}