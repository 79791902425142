import React from 'react';
import { BsCalendar, BsCalendarCheck, BsCalendarX, BsClockHistory } from "react-icons/bs";
import {
    FaAppleAlt,
    FaArrowCircleDown, FaArrowCircleUp, FaBeer, FaBirthdayCake, FaBookMedical, FaCannabis,
    FaChartBar, FaClinicMedical, FaClipboardList, FaFacebookF, FaFile, FaFileExport, FaFileMedical,
    FaFilePrescription,
    FaHeartbeat, FaIdCard, FaInstagram, FaLock, FaMapMarkerAlt,
    FaPills, FaRegBuilding,
    FaPrescriptionBottleAlt,
    FaRegAddressCard,
    FaRegChartBar, FaFileSignature,
    FaRegCommentDots,
    FaRegFileAlt, FaRegTimesCircle, FaRulerCombined, FaSchool, FaSmoking,
    FaStickyNote,
    FaTasks, FaTwitter, FaUserCheck, FaUserTie, FaVideo, FaWeight, FaWindowClose
} from "react-icons/fa";
import { FiArrowLeft, FiArrowRight, FiCamera, FiCheckSquare, FiEdit, FiSave, FiUserCheck, FiUserX } from "react-icons/fi";
import { GiBodyHeight, GiCancel, GiOpenFolder, GiStairsGoal } from "react-icons/gi";
import { ImLab, ImTable } from "react-icons/im";
import { IoIosCalendar } from "react-icons/io";
import {
    MdAccessTime, MdAddCircleOutline,
    MdAssignmentAdd, MdAttachMoney, MdOutlineSwapHoriz, MdSwapHoriz,
    MdAttachFile, MdBlock, MdCancel, MdCheckCircle, MdContentCopy, MdDescription, MdDirectionsRun, MdExpandLess, MdExpandMore, MdFlag, MdGroup, MdHelpOutline, MdHome, MdMailOutline, MdMoreHoriz, MdPeople, MdPermContactCalendar,
    MdPersonOutline, MdPhone, MdPhoneIphone,
    MdPublish, MdRemoveCircleOutline, MdRepeat, MdAssignment,
    MdSearch, MdSettings, MdSync, MdToday, MdWarning
} from 'react-icons/md';
import { RiForbidFill, RiCalendarLine, RiContactsBookLine, RiDossierLine, RiHealthBookLine, RiLineChartLine, RiMailLine, RiMailOpenLine, RiMapPinLine, RiMoreLine, RiPrinterLine, RiShieldCrossFill, RiShieldUserFill, RiStickyNoteLine, RiTestTubeFill, RiTodoFill, RiUserAddLine } from "react-icons/ri";
import { ReactComponent as BloodFactor } from '../../assets/img/icons/BloodFactor.svg';
import { ReactComponent as BloodType } from '../../assets/img/icons/BloodType.svg';
import { ReactComponent as DashboardAddAppointment } from '../../assets/img/icons/Dashboard_AddAppointment.svg';
import { ReactComponent as DashboardAddPatient } from '../../assets/img/icons/Dashboard_AddPatient.svg';
import { ReactComponent as SVGFamilyHistory } from '../../assets/img/icons/Icon_AFamiliares.svg';
import { ReactComponent as SVGAdverseEvents } from '../../assets/img/icons/Icon_AdverseEvent.svg';
import { ReactComponent as Agenda } from '../../assets/img/icons/Icon_Agenda.svg';
import { ReactComponent as SVGAttachments } from '../../assets/img/icons/Icon_Attach.svg';
import { ReactComponent as BMI } from '../../assets/img/icons/Icon_BMI.svg';
import { ReactComponent as BloodPressure } from '../../assets/img/icons/Icon_BloodPressure.svg';
import { ReactComponent as DDL } from '../../assets/img/icons/Icon_Dropdown.svg';
import { ReactComponent as SVGDrugs } from '../../assets/img/icons/Icon_Drugs.svg';
import { ReactComponent as Speciality } from '../../assets/img/icons/Icon_Especialidad.svg';
import { ReactComponent as SVGHabits } from '../../assets/img/icons/Icon_Habitos.svg';
import { ReactComponent as MedicalRecords } from '../../assets/img/icons/Icon_Historias_Clinicas.svg';
import { ReactComponent as LogoutIcon } from '../../assets/img/icons/Icon_Logout.svg';
import { ReactComponent as Notification } from '../../assets/img/icons/Icon_Notification.svg';
import { ReactComponent as Patients2 } from '../../assets/img/icons/Icon_Pacientes.svg';
import { ReactComponent as SVGProcedures } from '../../assets/img/icons/Icon_Procedimientos.svg';
import { ReactComponent as Protocol } from '../../assets/img/icons/Icon_Protocolo.svg';
import { ReactComponent as Settings } from '../../assets/img/icons/Icon_Settings.svg';
import { ReactComponent as Telemetry } from '../../assets/img/icons/Icon_Telemetry.svg';
import { ReactComponent as Inventory } from '../../assets/img/icons/Icon_Inventory.svg';
import { ReactComponent as VisitType } from '../../assets/img/icons/Icon_Tipo.svg';
import { ReactComponent as Upload } from '../../assets/img/icons/Icon_Upload.svg';
import { ReactComponent as VerTodos } from '../../assets/img/icons/Icon_VerTodos.svg';
import { ReactComponent as Protocols } from '../../assets/img/icons/Icon_protocolos.svg';
import { ReactComponent as VisitGroup } from '../../assets/img/icons/icon_Grupo.svg';
import { ReactComponent as SVGWaitingRoom } from '../../assets/img/icons/waiting_room.svg';

const BackgroundComponent = (props) => {
    return <div className="svg-container" style={props.style} >{props.children}</div>
}

export const IconSVG = ({ name, fill, stroke, style }) => {

    switch (name?.toLowerCase()) {
        case "add-appointment": return (<DashboardAddAppointment fill={fill} />);
        case "add-patient": return (<DashboardAddPatient fill={fill} />);
        case "dashboard": return (<MdHome fill={fill} />);
        case "agenda": return (<Agenda fill={fill} />);
        case "patients": return (<Patients2 fill={fill} />);
        case "medicalrecords": return (<MedicalRecords fill={fill} />);
        case "protocols": return (<Protocols fill={fill} />);
        case "settings": return (<Settings fill={fill} />);
        case "telemetry": return (<Telemetry fill={fill} />);
        case "inventory": return (<Inventory fill={fill} />);
        case "externalnote": return <FaStickyNote fill={fill} />;
        case "date": return (<BsCalendar fill={fill ? fill : "#247dbd"} />);
        case "speciality": return (<Speciality fill={fill ? fill : "#247dbd"} />);
        case "visittype": return (<VisitType fill={fill ? fill : "#247dbd"} />);
        case "visitgroup": return (<VisitGroup fill={fill ? fill : "#247dbd"} />);
        case "logout": return <Logout fill={fill} />;
        case "notification": return <Notification fill={fill} />;
        case "plus": return <Add />;
        case "dropdown": return <DDL fill={fill} />;
        case "save": return <Save />;
        case "publish": return <FiCheckSquare />;
        case "back": return <Back />;
        case "upload": return <Upload fill={fill ? fill : "#fff"} />;
        case "file": return <FaFile fill={fill ? fill : "#fff"} />;
        case "info": return <VerTodos fill={fill ? fill : "#fff"} />;
        case "export": return <Export />;

        case "consultorio": return <FaClinicMedical fill={fill ? fill : "#247dbd"} style={style} />;
        case "protocol": return <Protocol fill={fill ? fill : "#20ad9b"} style={style} />;
        case "project": return <GiOpenFolder fill={fill ? fill : "#20ad9b"} style={style} />;
        case "external": return <FaClinicMedical fill={fill ? fill : "#20ad9b"} style={style} />;
        case "other": return <FaClinicMedical fill={fill ? fill : "#247dbd"} style={style} />;

        case "personalhabits": return <SVGHabits fill={fill ? fill : "#20ad9b"} style={style} />;
        case "familyhistory": return <SVGFamilyHistory fill={fill ? fill : "#20ad9b"} style={style} />;
        case "adverseevents": return <SVGAdverseEvents fill={fill ? fill : "#20ad9b"} style={style} />;
        case "personalhistory": return <FaClipboardList fill={fill ? fill : "#20ad9b"} style={style} />;
        case "drugs": return <SVGDrugs fill={fill ? fill : "#20ad9b"} style={style} />;
        case "procedures": return <SVGProcedures fill={fill ? fill : "#20ad9b"} style={style} />;
        case "anthropometrics": return <FaRulerCombined fill={fill ? fill : "#20ad9b"} style={style} />;
        case "laboratory": return <RiTestTubeFill fill={fill ? fill : "#20ad9b"} style={style} />;
        case "vitals": return <FaHeartbeat fill={fill ? fill : "#20ad9b"} style={style} />;
        case "attachments": return <SVGAttachments fill={fill ? fill : "#20ad9b"} style={style} />;
        case "prescriptions": return <FaPrescriptionBottleAlt fill={fill ? fill : "#20ad9b"} style={style} />;
        case "orders": return <FaFilePrescription fill={fill ? fill : "#20ad9b"} style={style} />;

        case "height": return <BackgroundComponent style={{ height: '57px', width: '55px' }}><GiBodyHeight fill={fill ? fill : "#20ad9b"} style={{ height: '27px', width: '25px' }} /></BackgroundComponent>;
        case "weight": return <BackgroundComponent style={{ height: '57px', width: '55px' }}><FaWeight fill={fill ? fill : "#20ad9b"} style={{ height: '27px', width: '25px' }} /></BackgroundComponent>;
        case "bmi": return <BackgroundComponent style={{ height: '57px', width: '55px' }}><BMI fill={fill ? fill : "#20ad9b"} style={{ height: '27px', width: '25px' }} /></BackgroundComponent>;
        case "bloodpressure": return <BackgroundComponent style={{ height: '57px', width: '55px' }}><BloodPressure fill={fill ? fill : "#20ad9b"} style={{ height: '27px', width: '25px' }} /></BackgroundComponent>;
        case "heartrate": return <BackgroundComponent style={{ height: '57px', width: '55px' }}><FaHeartbeat fill={fill ? fill : "#20ad9b"} style={{ height: '27px', width: '25px' }} /></BackgroundComponent>;

        case "alcohol": return <BackgroundComponent style={{ height: '57px', width: '55px', backgroundColor: 'rgba(206,206,206,0.23)' }}><FaBeer style={{ height: '27px', width: '25px' }} fill={fill ? fill : "#20ad9b"} /></BackgroundComponent>;
        case "actividad física": return <BackgroundComponent style={{ height: '57px', width: '55px', backgroundColor: 'rgba(206,206,206,0.23)' }}><MdDirectionsRun style={{ height: '27px', width: '25px' }} fill={fill ? fill : "#20ad9b"} /></BackgroundComponent>;
        case "dieta": return <BackgroundComponent style={{ height: '57px', width: '55px', backgroundColor: 'rgba(206,206,206,0.23)' }}><FaAppleAlt style={{ height: '27px', width: '25px' }} fill={fill ? fill : "#20ad9b"} /></BackgroundComponent>;
        case "drogas": return <BackgroundComponent style={{ height: '57px', width: '55px', backgroundColor: 'rgba(206,206,206,0.23)' }}><FaCannabis style={{ height: '27px', width: '25px' }} fill={fill ? fill : "#20ad9b"} /></BackgroundComponent>;
        case "educacion": return <BackgroundComponent style={{ height: '57px', width: '55px', backgroundColor: 'rgba(206,206,206,0.23)' }}><FaSchool style={{ height: '27px', width: '25px' }} fill={fill ? fill : "#20ad9b"} /></BackgroundComponent>;
        case "ocupacion": return <BackgroundComponent style={{ height: '57px', width: '55px', backgroundColor: 'rgba(206,206,206,0.23)' }}><FaUserTie style={{ height: '27px', width: '25px' }} fill={fill ? fill : "#20ad9b"} /></BackgroundComponent>;
        case "tabaco": return <BackgroundComponent style={{ height: '57px', width: '55px', backgroundColor: 'rgba(206,206,206,0.23)' }}><FaSmoking style={{ height: '27px', width: '25px' }} fill={fill ? fill : "#20ad9b"} /></BackgroundComponent>;
        case "search": return <MdSearch fill={fill} stroke={stroke} />;
        case "edit": return <Edit />;
        case "patient": return <Patient />;

        case "bloodtype": return <BackgroundComponent style={{ height: '57px', width: '55px' }}><BloodType style={{ height: '35px', width: '35px' }} fill={fill ? fill : "#20ad9b"} /></BackgroundComponent>;
        case "bloodfactor": return <BackgroundComponent style={{ height: '57px', width: '55px' }}><BloodFactor style={{ height: '35px', width: '35px' }} fill={fill ? fill : "#20ad9b"} /></BackgroundComponent>;

        case "addappointment": return <AddAppointment />;
        case "waiting_room": return <SVGWaitingRoom fill={fill ? fill : "#20ad9b"} style={style} />;

        default:
            break;
    }

    return null;
}

export const Signature = (props) => { return <FaFileSignature {...props}>{props.children}</FaFileSignature> }
export const FormRestriction = (props) => { return <RiForbidFill {...props}>{props.children}</RiForbidFill> }
export const FormAdditional = (props) => { return <MdAssignmentAdd {...props}>{props.children}</MdAssignmentAdd> }
export const FormEarlyCompleted = (props) => { return <BsClockHistory {...props}>{props.children}</BsClockHistory> }
export const FormExtraProtocol = (props) => { return <MdAssignment {...props}>{props.children}</MdAssignment> }

export const Lock = (props) => { return <FaLock {...props}>{props.children}</FaLock> }
export const Today = (props) => { return <MdToday {...props}>{props.children}</MdToday> }
export const Help = (props) => { return <MdHelpOutline {...props}>{props.children}</MdHelpOutline> }
export const ViewMR = (props) => { return <RiHealthBookLine {...props}>{props.children}</RiHealthBookLine> }
export const View = (props) => { return <RiMoreLine {...props}>{props.children}</RiMoreLine> }
export const Search = (props) => { return <MdSearch {...props}>{props.children}</MdSearch> }
export const WaitingRoom = (props) => { return <SVGWaitingRoom {...props} fill={props.fill ? props.fill : "#20ad9b"} style={props.style}>{props.children}</SVGWaitingRoom> }
export const MoneyBill = (props) => { return <MdAttachMoney {...props} fill={props.fill ? props.fill : "#000"} style={props.style}>{props.children}</MdAttachMoney> }

export const Print = (props) => { return <RiPrinterLine {...props}>{props.children}</RiPrinterLine> }
export const Note = (props) => { return <RiStickyNoteLine {...props}>{props.children}</RiStickyNoteLine> }
export const AddAppointment = (props) => { return <RiCalendarLine {...props}>{props.children}</RiCalendarLine> }
export const Calendar = (props) => { return <BsCalendar {...props}>{props.children}</BsCalendar> }
export const CalendarCheck = (props) => { return <BsCalendarCheck {...props}>{props.children}</BsCalendarCheck> }
export const CalendarCross = (props) => { return <BsCalendarX {...props}>{props.children}</BsCalendarX> }

export const Audit = (props) => { return <MdAccessTime {...props}>{props.children}</MdAccessTime> }

export const Add = (props) => { return <MdAddCircleOutline {...props}>{props.children}</MdAddCircleOutline> }
export const Repeat = (props) => { return <MdRepeat {...props}>{props.children}</MdRepeat> }
export const RemoveCircle = (props) => { return <MdRemoveCircleOutline {...props}>{props.children}</MdRemoveCircleOutline> }
export const More = (props) => { return <MdMoreHoriz {...props}>{props.children}</MdMoreHoriz> }
export const Attach = (props) => { return <MdAttachFile {...props}>{props.children}</MdAttachFile> }
export const Remove = (props) => { return <FaRegTimesCircle {...props}>{props.children}</FaRegTimesCircle> }
export const Edit = (props) => { return <FiEdit {...props}>{props.children}</FiEdit> }
export const Copy = (props) => { return <MdContentCopy {...props}>{props.children}</MdContentCopy> }
export const Close = (props) => { return <FaWindowClose {...props}>{props.children}</FaWindowClose> }
export const Closed = (props) => { return <FaLock {...props}>{props.children}</FaLock> }
export const Check = (props) => { return <FiCheckSquare {...props}>{props.children}</FiCheckSquare> }
export const PersonCheck = (props) => { return <FaUserCheck {...props}>{props.children}</FaUserCheck> }
export const PersonCheckLine = (props) => { return <FiUserCheck {...props}>{props.children}</FiUserCheck> }
export const PersonCrossLine = (props) => { return <FiUserX {...props}>{props.children}</FiUserX> }

export const Back = (props) => { return <FiArrowLeft {...props}>{props.children}</FiArrowLeft> }
export const Next = (props) => { return <FiArrowRight {...props}>{props.children}</FiArrowRight> }
export const Export = (props) => { return <FaFileExport {...props}>{props.children}</FaFileExport> }
export const Camera = (props) => { return <FiCamera {...props}>{props.children}</FiCamera> }
export const Logout = (props) => { return <LogoutIcon {...props}>{props.children}</LogoutIcon> }

export const Up = (props) => { return <FaArrowCircleUp {...props}>{props.children}</FaArrowCircleUp> }
export const Down = (props) => { return <FaArrowCircleDown {...props}>{props.children}</FaArrowCircleDown> }
export const Swap = (props) => { return <MdOutlineSwapHoriz {...props}>{props.children}</MdOutlineSwapHoriz> }

export const ViewPractitioner = (props) => { return <MdPermContactCalendar {...props}>{props.children}</MdPermContactCalendar>; }
export const ViewProtocol = (props) => { return <RiMoreLine {...props}>{props.children}</RiMoreLine> }
export const ViewVisit = (props) => { return <RiTodoFill {...props}>{props.children}</RiTodoFill> }

export const Brief = (props) => { return <MdDescription {...props}>{props.children}</MdDescription> }
export const Practitioner = (props) => { return <MdPeople {...props}>{props.children}</MdPeople> }
export const VisitTracking = (props) => { return <RiDossierLine {...props}>{props.children}</RiDossierLine> }
export const AddPatient = (props) => { return <RiUserAddLine {...props}>{props.children}</RiUserAddLine> }
export const Patient = (props) => { return <MdPersonOutline {...props}>{props.children}</MdPersonOutline> }
export const Patients = (props) => { return <MdGroup {...props}>{props.children}</MdGroup> }
export const Config = (props) => { return <MdSettings {...props}>{props.children}</MdSettings> }
export const ExtraProtocol = (props) => { return <MdFlag {...props}>{props.children}</MdFlag> }
export const Visit = (props) => { return <FaFileMedical {...props}>{props.children}</FaFileMedical> }

export const Laboratory = (props) => { return <ImLab {...props}>{props.children}</ImLab> }
export const PrincipalInvestigator = (props) => { return <MdSearch {...props}>{props.children}</MdSearch> }
export const Status = (props) => { return <FaTasks {...props}>{props.children}</FaTasks> }
export const Phase = (props) => { return <GiStairsGoal {...props}>{props.children}</GiStairsGoal> }


export const Save = (props) => { return <FiSave {...props}>{props.children}</FiSave> }
export const Publish = (props) => { return <MdPublish {...props}>{props.children}</MdPublish>; }
export const Cancel = (props) => { return <MdCancel {...props}>{props.children}</MdCancel>; }
export const CanceLine = (props) => { return <GiCancel {...props}>{props.children}</GiCancel>; }
export const Change = (props) => { return <MdSwapHoriz {...props}>{props.children}</MdSwapHoriz>; }

export const Warning = (props) => { return <MdWarning {...props}>{props.children}</MdWarning>; }

export const Unread = (props) => { return <RiMailLine {...props}>{props.children}</RiMailLine>; }
export const Readed = (props) => { return <RiMailOpenLine {...props}>{props.children}</RiMailOpenLine>; }

export const Enabled = (props) => { return <MdCheckCircle {...props}>{props.children}</MdCheckCircle> }
export const Disabled = (props) => { return <MdBlock {...props}>{props.children}</MdBlock> }

export const IconPersonalHabits = () => { return (<i className="fas fa-joint" aria-hidden="true"></i>); }
export const IconFamilyHistory = () => { return (<i className="fas fa-heart" aria-hidden="true"></i>); }
export const IconAdverseEvent = () => { return (<i className="fas fa-id-card-alt" aria-hidden="true"></i>); }
export const IconDrug = () => { return (<i className="fas fa-pills" aria-hidden="true"></i>); }
export const Drug = (props) => { return <FaPills {...props}>{props.children}</FaPills> }
export const IconProcedure = () => { return (<i className="fas fa-procedures" aria-hidden="true"></i>); }
export const IconAnthropometric = () => { return (<i className="fas fa-heart" aria-hidden="true"></i>); }
export const IconAdherence = () => { return (<i className="fas fa-calculator" aria-hidden="true"></i>); }
export const IconAttachment = () => { return (<i className="fas fa-paperclip" aria-hidden="true"></i>); }

export const ContactInfo = (props) => { return <FaRegAddressCard {...props}>{props.children}</FaRegAddressCard> }
export const RelatedContact = (props) => { return <RiContactsBookLine {...props}>{props.children}</RiContactsBookLine> }

export const CellPhone = (props) => { return <MdPhoneIphone {...props}>{props.children}</MdPhoneIphone> }
export const Phone = (props) => { return <MdPhone {...props}>{props.children}</MdPhone> }
export const OnSite = (props) => { return <FaRegBuilding {...props}>{props.children}</FaRegBuilding> }
export const VideoCall = (props) => { return <FaVideo {...props}>{props.children}</FaVideo> }
export const Email = (props) => { return <MdMailOutline {...props}>{props.children}</MdMailOutline> }
export const Location = (props) => { return <FaMapMarkerAlt {...props}>{props.children}</FaMapMarkerAlt> }
export const Facebook = (props) => { return <FaFacebookF {...props}>{props.children}</FaFacebookF> }
export const Instagram = (props) => { return <FaInstagram {...props}>{props.children}</FaInstagram> }
export const Twitter = (props) => { return <FaTwitter {...props}>{props.children}</FaTwitter> }

export const Address = (props) => { return <RiMapPinLine {...props}>{props.children}</RiMapPinLine> }
export const Date = (props) => { return <IoIosCalendar {...props}>{props.children}</IoIosCalendar> }
export const Identification = (props) => { return <FaIdCard {...props}>{props.children}</FaIdCard> }
export const Doctor = (props) => { return <MdPersonOutline {...props}>{props.children}</MdPersonOutline> }
export const Statistics = (props) => { return <FaRegChartBar {...props}>{props.children}</FaRegChartBar> }
export const File = (props) => { return <FaFile {...props}>{props.children}</FaFile> }

export const Sync = (props) => { return <MdSync {...props}>{props.children}</MdSync> }

export const BirthDate = (props) => { return <FaBirthdayCake {...props}>{props.children}</FaBirthdayCake> }
export const MedicalInsurance = (props) => { return <RiShieldCrossFill {...props}>{props.children}</RiShieldCrossFill> }
export const MedicalInsuranceNumber = (props) => { return <RiShieldUserFill {...props}>{props.children}</RiShieldUserFill> }
export const MedicalRecord = (props) => { return <FaBookMedical {...props}>{props.children}</FaBookMedical> }

export const Expand = (props) => { return <MdExpandMore {...props}>{props.children}</MdExpandMore> }
export const Contract = (props) => { return <MdExpandLess {...props}>{props.children}</MdExpandLess> }
export const Summary = (props) => { return <FaRegFileAlt {...props}>{props.children}</FaRegFileAlt> }

export const Chart = (props) => { return <FaChartBar {...props}>{props.children}</FaChartBar> }
export const Table = (props) => { return <ImTable {...props}>{props.children}</ImTable> }
export const ChartBar = (props) => { return <FaChartBar {...props}>{props.children}</FaChartBar> }
export const ChartLine = (props) => { return <RiLineChartLine {...props}>{props.children}</RiLineChartLine> }

export const Comments = (props) => { return <FaRegCommentDots {...props}>{props.children}</FaRegCommentDots> }


