export function Url(basePath) {
    const BasePath = basePath;
    return (path) => `${BasePath}${path ?? ""}`
}
export function ResponseResult({ succeeded, errors, value = null }) {
    this.Succeeded = succeeded ?? false;
    this.Value = value ?? null
    this.Errors = errors ?? [];
}

export function PaginationResult({ values = [], pageSize = 10, totalPages = 1, totalCount = 0, pageNumber = 1, hasNext = false, hasPrevious = false, search = '', sortColumn = null, sortOrder = null } = {}) {
    this.Values = values;
    this.TotalPages = totalPages;
    this.PageSize = pageSize;
    this.TotalCount = totalCount;
    this.PageNumber = pageNumber;
    this.HasNext = hasNext;
    this.HasPrevious = hasPrevious;
    this.Search = search;
    this.SortColumn = sortColumn;
    this.SortOrder = sortOrder;

    this.ToPage = function (page) {
        if (page <= 0) return null
        if (page > this.TotalPages) return null

        return new PaginationRequest({
            pageNumber: page,
            pageSize: this.PageSize,
            search: this.Search,
            sortColumn: this.SortColumn,
            sortOrder: this.SortOrder
        })
    }
}

export function PaginationRequest({ search = '', pageNumber = 1, pageSize = 10, sortColumn = null, sortOrder = null, tenantId } = {}) {
    this.search = search;
    this.pageNumber = pageNumber;
    this.pageSize = pageSize;
    this.sortColumn = sortColumn;
    this.sortOrder = sortOrder;
    this.tenant = tenantId
}

export function GetBlobData({ data, headers }) {
    const blob = new Blob([data], { type: data.type })
    const contentDisposition = headers['content-disposition']
    let fileName = 'unknown'

    if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/)
        if (fileNameMatch.length > 1)
            fileName = fileNameMatch[1].replace(/['"]+/g, '');
    }

    return { blob, fileName }
}

export class EnumValue {
    constructor(value, name) {
        this.Value = value;
        this.Name = name;
    }

    static Unknown() {
        return new EnumValue(-1, 'Unknown')
    }
}

export function GroupBy(array, keySelector) {
    return array.reduce((map, item) => {
        const key = keySelector(item);
        if (!map.has(key)) { map.set(key, []) }
        map.get(key).push(item);
        return map;
    }, new Map());
}

export function UniqueId() {
    return "id" + Math.random().toString(16).slice(2)
}