import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import Card from "../../../components/Cards/Card";
import { Col, Row } from 'react-bootstrap';
import { Field } from '../../../components/Common/Field/Field';
import { useTranslation } from 'react-i18next';
import Loader from '../../../components/Common/Loader';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import moment from 'moment';
import { ErrorMessage, Success } from '../../../components/Notifications/Notifications';
import useConfirmationModal from '../../../components/Modals/ConfirmationModal/useConfirmationModal';
import InventoryOperationService from '../../../api/Inventory/InventoryOperationService';
import useInventoryCart from '../../Inventory/Hooks/useInventoryCart';
import { MoveTypes } from '../../../api/Inventory/Entities/InventoryMovementEntities';
import { AssignmentType } from '../../../api/Inventory/Entities/InventoryEntities';


export function ProtocolDispenseConfirmForm({ match: { params } }) {
    const history = useHistory();
    const { t } = useTranslation();
    const { dispenseId, protocolId } = params
    const [move, isLoading] = useInventoryCart(dispenseId, MoveTypes.ProtocolDispense.Name, AssignmentType.Protocol.Name, protocolId);
    const [Modal, modalActions] = useConfirmationModal();
    const [isWorking, setIsWorking] = useState(false)

    if (move == null || move.id == 0) return (<Loader isLoading={true}></Loader>)

    const handleCancel = () => {
        history.push(`/admin/protocols/${protocolId}`)
    }

    const handleConfirmationChange = (item, confirmation) => {
        if (confirmation == null) return
        confirmation = parseInt(confirmation)
        try {
            item.confirmQuantity(confirmation)
        } catch (e) {
            ErrorMessage(e.message)
        }
    }

    const columns = [
        { dataField: 'item.presentation', text: t('commons.description'), headerStyle: { width: '7rem' } },
        { dataField: 'release.lotNumber', text: t('inventory.commons.lotNumber'), headerStyle: { width: '3rem' } },
        { dataField: 'quantity', text: t('commons.quantity'), headerStyle: { width: '3rem' } },
        {
            dataField: 'confirmedQuantity', text: 'Confirmada', headerStyle: { width: '3rem' }, formatter: (cell, row) => {
                return (<input className='form-control form-control-sm' type='number' min="0" defaultValue={row.confirmedQuantity} onChange={e => handleConfirmationChange(row, e.target.value)} />)
            }
        },

    ]

    if (move.assignmentType == 'protocol')
        columns.push({ dataField: 'assignment.kitNumber', text: t('inventory.commons.kitNumber'), headerStyle: { width: '3rem' } })

    async function onHandleSubmit() {
        modalActions.Show({
            title: t('inventory.movements.confirmations.completeTitle'),
            textContent: t('inventory.movements.confirmations.completeTextContent'),
            buttonText: t('buttons.confirm'),
            onConfirmation: async () => {
                if (isWorking == true) return;
                try {
                    setIsWorking(true)
                    const response = await InventoryOperationService.ExcecuteOperation(move)
                    if (response.Succeeded) {
                        history.push(`/admin/protocols/${protocolId}`)
                        Success("commons.successSave")
                    }
                    else
                        ErrorMessage("errors.serverError")
                } catch (e) {
                    ErrorMessage("errors.serverError")

                } finally {
                    setIsWorking(false)
                }
            }
        })
    }

    return (<>
        <Card
            content={<>
                <Loader isLoading={isLoading}>
                    <div className='text-center'>
                        <label className="info-title info-title-lg">Confirmacion de dispensa</label>
                    </div>
                    <Row>
                        <Col md={4} sm={12}>
                            <div className="info" style={{ justifyContent: 'space-between!important' }}>
                                <Field title={'ID'} value={move.id} spaceType={'between'} />
                                <Field title={t('commons.type')} spaceType={'between'} value={t(`inventory.movements.moveType.${move.moveType?.toLowerCase()}`)} />
                            </div>
                        </Col>
                        <Col md={4} sm={12}>
                            <div className="info" style={{ justifyContent: 'space-between!important' }}>
                                <Field title={t('commons.status')} spaceType={'between'} value={"Pendiente de confirmacion"} />
                                <Field title={t('commons.date')} spaceType={'between'} value={moment(move.createAt).format("DD/MM/YYYY HH:mm")} />
                            </div>
                        </Col>
                        <Col md={4} sm={12}>
                            <div className="info" style={{ justifyContent: 'space-between!important' }}>
                                <Field title={t('inventory.movements.commons.destination')} spaceType={'between'}
                                    value={t(`inventory.movements.assignmentType.${move.assignment.assignmentType?.toLowerCase()}`)} />
                                <Field title={t('inventory.movements.commons.assignment')} spaceType={'between'} value={move.assignName} />
                            </div>
                        </Col>
                        <Col sm={12}>
                            <div>
                                <hr />
                                <div className='text-center' style={{ marginTop: "1rem" }}>
                                    <strong className='info-label'>{t('inventory.commons.detail')}</strong>
                                </div>
                                <div style={{ height: '300px', overflow: 'hidden auto' }}>
                                    <BootstrapTable
                                        bordered={false}
                                        hover
                                        remote
                                        keyField='id'
                                        pagination={paginationFactory({
                                            sizePerPage: move?.items?.length ?? 0,
                                            totalSize: move?.items?.length ?? 0,
                                            hideSizePerPage: true
                                        })}
                                        onTableChange={() => { }}
                                        classes="table-spaced table-spaced-small"
                                        wrapperClasses="table-responsive"
                                        data={move.items}
                                        columns={columns}
                                        loading={isLoading}
                                        noDataIndication={() => <>{t("commons.noData")}</>}
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <div style={{ textAlign: 'right', display: 'flex', justifyContent: 'end' }}>
                                <button className="btn btn-secondary" type="button" onClick={handleCancel}>{t('buttons.back')}</button>
                                <button type="button" style={{ marginLeft: '1rem' }} className='btn btn-success' disabled={false} onClick={onHandleSubmit}>{t('buttons.finish')}</button>
                            </div>
                        </Col>
                    </Row>
                </Loader>
            </>}
        />
        <Modal />
    </>)
}