import axios from "axios";
import { GetBlobData, PaginationRequest, PaginationResult, ResponseResult, Url } from "../Common/Util";
import { IotDevice } from "./Entities/IotDevice";

const GetUrl = Url("api/v1/IotDevice");

export default {
    Find,
    AssignFreezer,
    GetById,
    Create,
    Delete,
    UploadCertificate,
    GetCertificate
}

async function Find(request = {}) {
    const { search, pageNumber, sortOrder, pageSize } = request

    const response = await axios.get(GetUrl('/Find'), { params: new PaginationRequest({ sortColumn: 'name', search, pageNumber, sortOrder, pageSize }) });
    const data = new PaginationResult(response.data)
    data.Values = data.Values.map(f => new IotDevice(f))
    return data;

}

async function AssignFreezer({ freezerId, deviceId }) {
    if (freezerId == null || freezerId == '') throw new Error('FreezerId: The ID can not be empty')
    if (isNaN(deviceId)) throw new Error('DeviceId: Numeric ID is required');

    const response = await axios.post(GetUrl('AssignFreezer'), { Id: deviceId, FreezerId: freezerId });
    return response.data.Succeeded;
}
async function GetById(id) {
    if (isNaN(id)) throw new Error('Numeric ID is required')

    const response = await axios.get(GetUrl(`/${id}`))
    return new IotDevice(response.data.value);
}

async function Create(device) {
    const response = await axios.post(GetUrl(), { ...device });
    return new ResponseResult({ ...response.data, value: new IotDevice(response.data.value) });
}

async function Delete(deviceId) {
    if (isNaN(deviceId)) throw new Error('Numeric ID is required')

    const response = await axios.delete(GetUrl(`/${deviceId}`));
    return new ResponseResult({ ...response.data })
}

async function UploadCertificate(deviceId, file) {
    const formData = new FormData();
    formData.append('certificate', file);
    const response = await axios.post(GetUrl(`/UploadCertificate/${deviceId}`), formData, {
        headers: {
            'Content-Type': 'application/pdf'
        }
    });
    return new ResponseResult(response.data)
}

async function GetCertificate(path) {
    const response = await axios.get(path, { responseType: 'blob' })
    const blob = GetBlobData(response)
    return blob
}