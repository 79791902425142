import React, { useEffect, useState } from 'react';
import { InventoryForm } from "./InventoryForm";
import { InventoryItemCart } from "./InventoryItemCart";
import useInventoryCart from '../Hooks/useInventoryCart';
import Loader from '../../../components/Common/Loader';
import { GeneralAssigment } from '../../../api/Inventory/Entities/InventoryEntities';
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom"
import { ErrorMessage, Success } from '../../../components/Notifications/Notifications';
import InventoryOperationService from '../../../api/Inventory/InventoryOperationService';
import InventoryMedicationService from '../../../api/Inventory/InventoryMedicationService';
import useConfirmationModal from '../../../components/Modals/ConfirmationModal/useConfirmationModal';

export function InventoryMoveForm({ title, moveId, moveType, assignmentType, onlyLocalInventory = false }) {
    const { t } = useTranslation()
    const history = useHistory();
    const [isWorking, setIsWorking] = useState(false)
    const [move, isLoading, actions] = useInventoryCart(moveId, moveType, assignmentType)
    const [Modal, modalActions] = useConfirmationModal();

    if (moveType == undefined)
        throw new Error("MoveType not Assigned")

    function handleNewItem(item) {
        actions.AddItem(item.selectedItem, item.selectedRelease, item.quantity, GeneralAssigment)
    }

    function onDeleteItem(item, _) {
        actions.RemoveItem(item)
    }

    function handleItemChange(quantity, item) {
        actions.UpdateQuantity(item, quantity)
    }

    async function onHandleSubmit() {
        modalActions.Show({
            title: t('inventory.movements.confirmations.completeTitle'),
            textContent: t('inventory.movements.confirmations.completeTextContent'),
            buttonText: t('buttons.confirm'),
            onConfirmation: async () => {
                if (isWorking == true) return;
                try {
                    setIsWorking(true)
                    const response = await InventoryOperationService.ExcecuteOperation(move)
                    if (response.Succeeded) {
                        history.push("/admin/inventory")
                        Success("commons.successSave")
                    }
                    else
                        ErrorMessage("errors.serverError")
                } catch (e) {
                    ErrorMessage("errors.serverError")

                } finally {
                    setIsWorking(false)
                }
            }
        })
    }
    async function onHandlePending() {
        modalActions.Show({
            title: t('inventory.movements.confirmations.completeTitle'),
            textContent: t('inventory.movements.confirmations.completeTextContent'),
            buttonText: t('buttons.confirm'),
            onConfirmation: async () => {
                try {
                    setIsWorking(true)
                    const response = await InventoryOperationService.SavePending(move)

                    if (response.Succeeded) {
                        Success("commons.successSave")
                        history.push("/admin/inventory")
                    }
                    else
                        ErrorMessage("errors.serverError")
                } catch (e) {
                    ErrorMessage("errors.serverError")
                } finally {
                    setIsWorking(false)
                }
            }
        })
    }

    function onHandleCancel() {
        history.push("/admin/inventory")
    }
    async function onHandleSearchItem(value) {
        const result = await InventoryMedicationService.Find({ term: value, onlyLocal: onlyLocalInventory, pageSize: 100 })
        return result.Value
    }

    return (<>
        <Loader isLoading={isLoading && move != undefined}>
            <div className='max-width-1500 margin-center'>
                <div className='container-fluid form-container' style={{ fontWeight: "bolder" }}>
                    <h5><strong>{title}</strong></h5>
                </div>
                <div className='container-fluid form-container'>
                    <div className='right-side' style={{ height: 'max-content', maxWidth: 'max-content' }}>
                        <InventoryForm handleSubmit={handleNewItem} handleSearchItem={onHandleSearchItem} />
                    </div>
                    <div className='left-side' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '45vh' }}>
                        <InventoryItemCart items={move.items} onDeleteItem={onDeleteItem} onItemsChange={handleItemChange} />
                        <div style={{ textAlign: 'right', alignSelf: 'self-end' }}>
                            <button className='btn btn-secondary ml-1' style={{ marginRight: '1rem' }} onClick={onHandleCancel}>{t('buttons.cancel')}</button>
                            <button className='btn btn-warning   ml-1' style={{ marginRight: '1rem' }} disabled={!(move.items.length > 0)} onClick={onHandlePending}>{t('buttons.pending')}</button>
                            <button className='btn btn-success' disabled={!(move.items.length > 0)} onClick={onHandleSubmit}>{t('buttons.finish')}</button>
                        </div>
                    </div>
                </div>
            </div>
        </Loader>
        <Modal />
    </>)
}